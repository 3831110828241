/** @jsx jsx */

import { jsx } from '@emotion/core';
import { InstructionsPage, Model } from "../../_shared/interfaces/model";
import { ViewInstructionsComponent } from "../../_shared/view-instructions/view-instructions.component";
import { Button } from "../../_shared/button";
import { Equipment } from '../../_shared/interfaces/equipment';
import { useState } from 'react';
import './a1-registration.css';

interface A1InstallationProps {
    model?: Model;
    equipment?: Equipment;
    continue: () => void;
};

export const A1InstallationInstructions: React.FC<A1InstallationProps> = props => {
    const [showInstructions, setShowInstructions] = useState(false);
    const { model, equipment } = props;
    const instructions = model && model.instructions;
    if (!instructions) {
        return <div>
            No instructions configured for this model, you're on your own.
            <Button onClick={props.continue} title="Thanks">Thanks</Button>
        </div>;
    }

    return (<div className='a1-installation-instructions'>
        <div className='a1-registration-header'>Install {model && model.name || 'Equipment'}{equipment && `: ${equipment.name}`}</div>
        <Button title="View Instructions" onClick={() => setShowInstructions(!showInstructions)} />
        <ViewInstructionsComponent
            instructions={instructions}
            visible={showInstructions}
            onClose={props.continue}
        />
    </div>);
}