import React, { useEffect, useState } from 'react';
import { Modal, Alert, message, Icon } from 'antd';
import { InputLabel } from '../../_shared/input-label/input-label.component';
import moment from 'moment';
import { get, size } from 'lodash';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { replaceEquipmentController } from '../manufacture-process/manufacture-process.utils';
import { AppState } from '../../app.state';
import { Row } from '../../_shared/row';
import colors from '../../_shared/colors';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { getEqType, wait } from '../../utils';

export interface ReplaceProps extends RouteComponentProps {
  onClose: () => void;
  visible: boolean;
  equipment: Equipment;
  sState: AppState;
}

interface Event {
  name: string;
  title: string;
  state: string;
  data?: {};
}

interface IState {
  loading: boolean;
  error?: string;
  controllerSerial: string;
  replaceDate: moment.Moment;
  results?: { errors: string[] };
  events: Event[];
  eventsToRun?: Event[];
  newEquipment?: Equipment;
}

const GETEVENTS = (eq: Equipment) => {
  const eqtype = getEqType(eq);
  if (eqtype === 'chair') {
    return [
      {
        name: 'doesntExist',
        title: 'Check to make sure device doesnt already exist',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'createNewEquip',
        title: 'Creating the new Equipment Record',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'updateOldEquip',
        title: 'Updating the old equipment record',
        state: 'queue',
        killOnFail: true,
      },
    ]
  } else {
    return [
      // 'deviceIsOnline', //communicate with the device see if it is online
      // 'doesntExist', //check if the device already exists
      // 'createNewEquip', //create the new equipment record
      // 'updateOldEquip', //update the old eq record so that it doesn't show on the dashboard
      // 'updateSchedule', //returns as { success: [], errors: [] } on the schedule days updates
      // 'updateModel', //update the model on the particle equipment device
      // 'updateSerial', //update the serial on the particle equipment device
      // 'updateInstallDate', //update the install date on the particle equipment device
      // 'updateRuntime', //update the runtime on the particle equipment device { success: [], errors: [] }
      // 'updateComponentSerial', //update the runtime on the particle equipment device components { success: [], errors: [] }
      {
        name: 'deviceIsOnline',
        killOnFail: true,
        title: 'Checking if device is online',
        state: 'queue',
        modifyError: (err?: Error) => {
          if (err) {
            return 'Device is Offline';
          }
          return undefined;
        },
      },
      {
        name: 'doesntExist',
        title: 'Check to make sure device doesnt already exist',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'updateSchedule',
        title: 'Transfer schedule to new device',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'updateModel',
        title: 'Writing model to new device',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'updateSerial',
        title: 'Updating serial number on new device',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'updateInstallDate',
        title: 'Updating the install date on new device',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'updateRuntime',
        title: 'Updating runtime values on new device',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'updateComponentSerial',
        title: 'Updating component serial numbers on new device',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'createNewEquip',
        title: 'Creating the new Equipment Record',
        state: 'queue',
        killOnFail: true,
      },
      {
        name: 'updateOldEquip',
        title: 'Updating the old equipment record',
        state: 'queue',
        killOnFail: true,
      },
    ]
  }
}

export const ReplaceController = withRouter((props: ReplaceProps) => {
  const [state, setState] = useState(({
    loading: false,
    error: undefined,
    controllerSerial: '',
    replaceDate: moment(),
    results: undefined,
    eventsToRun: undefined,
    events: [],
  } as unknown) as IState);

  const onClose = () => {
    setState(s => ({
      ...s,
      results: undefined,
      eventsToRun: undefined,
      controllerSerial: '',
      replaceDate: moment(),
      newEquipment: undefined,
      events: s.events.map(e => ({ ...e, data: undefined, state: 'queue' })),
    }));
    props.onClose && props.onClose();
  };

  const updateEvent = (name: string, _state: string, data: {}) => {
    setState(s => ({
      ...s,
      events: s.events.map(e => {
        const isEvent = e.name === name;
        return {
          ...e,
          state: isEvent ? _state : e.state,
          data: isEvent ? data : get(e, 'data'),
        };
      }),
    }));
  };

  const submit = async () => {
    try {
      if (state.newEquipment) {
        props.onClose();
        props.history.goBack();
        await wait(200);
        return props.history.push('/dashboard/equipmentDetails', {
          equipment: state.newEquipment,
        });
      }
      setState(s => ({ ...s, loading: true }));

      const { equipment, sState } = props;
      const me = get(sState, 'auth.user');

      const results = await replaceEquipmentController({
        controllerSerial: state.controllerSerial,
        replaceDate: state.replaceDate,
        equipment,
        onEventUpdate: updateEvent,
        events: state.eventsToRun || state.events,
      });

      const errors = results.filter((event: {}) => {
        const hasError = get(event, 'error', get(event, 'errors'));
        return !!hasError || get(event, 'data') === null;
      });

      console.warn({
        errors,
      });

      trackEvent(eventTypes.eq_replace_controller, {
        equipment: props.equipment,
        errors,
      });

      let newEquipment: Equipment;
      if (!size(errors)) {
        message.success('Controller swap success!');
        const allevents = [...state.events, ...results]
          .filter(f => !!f.data)
          .map(e => ({ ...e, name: get(e, 'name', get(e, 'event')) }));
        newEquipment = new _Equipment(get(
          allevents.find((e: {}) => get(e, 'name') === 'createNewEquip'),
          'data',
          {}
        ) as Equipment);
      }

      //@ts-ignore
      setState(s => ({
        ...s,
        newEquipment,
        eventsToRun: errors.map((e: {}) =>
          s.events.find(ev => get(ev, 'name') === get(e, 'event'))
        ),
        results: { errors },
      }));
    } catch (err) {
      message.error(err.message);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  };

  const visible = !!props.visible;

  useEffect(() => {
    if (visible) {
      setState(s => ({ ...s, events: GETEVENTS(props.equipment) }))
    }
  }, [visible]);

  const canRetry = state.results && !!size(state.results.errors);
  const hasNewEq = !!state.newEquipment;

  return (
    <Modal
      visible={visible}
      title={'New Aeras Controller Info'}
      centered
      closable
      confirmLoading={state.loading}
      okText={
        canRetry
          ? 'Resend Failed Updates'
          : hasNewEq
          ? 'Go to New Equipment'
          : 'Update'
      }
      onCancel={onClose}
      onOk={submit}
      okButtonProps={{
        disabled:
          !state.controllerSerial ||
          (state.results && !size(state.results.errors) && !hasNewEq)
            ? true
            : false,
      }}
      // cancelButtonProps={{
      //   disabled: !state.results,
      // }}
    >
      {(!!state.loading || !!state.results) && (
        <div>
          {state.events.map(event => {
            const hasError = get(event, 'data.error.message');
            const errorMessage = get(event, 'modifyError', () =>
              get(event, 'data.error')
            )(get(event, 'data.error'));
            return (
              <Row>
                <div>{event.title}</div>
                <div title={errorMessage} style={{ marginLeft: 'auto' }}>
                  {event.state === 'complete' ? (
                    <Icon
                      style={{
                        color: hasError ? colors.error : colors.success,
                      }}
                      type={hasError ? 'close-circle' : 'check-circle'}
                    />
                  ) : event.state === 'start' ? (
                    <Icon type="loading" />
                  ) : (
                    <Icon type="stop" />
                  )}
                </div>
              </Row>
            );
          })}
        </div>
      )}

      {!state.loading && !state.results && (
        <div>
          {state.error && (
            <Alert
              message={state.error}
              closable
              type="error"
              onClose={() => setState(s => ({ ...s, error: undefined }))}
            />
          )}
          <InputLabel
            label="New Aeras Controller SN"
            value={state.controllerSerial}
            onChangeText={controllerSerial =>
              setState(s => ({ ...s, controllerSerial }))
            }
          />
          <InputLabel
            label="Date of Replacement"
            type={'date'}
            value={state.replaceDate}
            onChange={e => setState(s => ({ ...s, replaceDate: moment(e) }))}
          />
        </div>
      )}
    </Modal>
  );
});
