/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { firstKey, getUrlParams, parseJson, userHasRole } from '../utils';
import { getEquipmentBySN } from '../_shared/services/manage-equipment.service';
import { get, set, size } from 'lodash';
import { Equipment, EquipmentRecord } from '../_shared/interfaces/equipment';
import { message } from 'antd';
import styles from './styles';
import { Loading } from '../_shared/icon';
import { Auth } from './auth';
import { isLoggedIn } from '../utils';
import { useSelector } from 'react-redux';
import { AppState } from '../app.state';
import { EqRegistrationForm } from './eq';
import { OrgCreate } from './org';
import colors from '../_shared/colors';
import { withRouter } from 'react-router-dom';
import { RouterProps } from 'react-router';

export const REG_KEY = 'EQRegistration';

interface IProps extends RouterProps {

}

interface IState {
  ls: {
    //what gets sent over in the EQRegistration localstorage object
    equipment?: Equipment;
  }

  equipmentToLoad: Equipment[];

  loadedEquipment: {
    [key: string]: EquipmentRecord;
  }

  loading: boolean;

  authState: any;
}

let displayedMessage = false;

export const EQRegistration = withRouter((props: IProps) => {
  const user = useSelector(s => get(s, 'auth.user'));
  const loggedIn = !!useSelector(s => isLoggedIn(s as AppState));
  const hasOrg = get(user, 'orgId');
  const [state, setState] = useState({
    ls: {
      //gets loaded from what is in the EQRegistration localstorage object, along with whatever else. Only really looking for "equipment"
      equipment: undefined,
    },

    equipmentToLoad: [],

    loadedEquipment: {},
    loading: true,

    authState: {}
  } as IState);

  const getEqFromLocalStorage = () => {
    return new Promise((res) => {
      const json = localStorage.getItem(REG_KEY);

      const params = getUrlParams(window.location.href);
      const obj = params && params.serialnumber ? { equipment: params } : parseJson(json as string);

      if (obj.equipment) {
        localStorage.setItem(REG_KEY, JSON.stringify(obj));
      }

      const equipmentToLoad = obj.equipment ? [obj.equipment] : [];
      set(state, 'equipmentToLoad', equipmentToLoad);
      setState(s => ({ ...s, ls: obj, equipmentToLoad }))
      setTimeout(res, 250);
    })
  }

  const loadEquipment = async () => {
    try {
      setState(s => ({ ...s, loading: true }))

      const loadedEquipment = {};
      await Promise.all(state.equipmentToLoad.map(async e => {
        const eq = await getEquipmentBySN(e.serialnumber as string);

        if (!eq) {
          return message.error(`No equipment with that Serial Number exists.`)
        }
        set(loadedEquipment, e.serialnumber as string, { ...eq, activationcode: e.activationcode })
      }))
      setState(s => ({ ...s, loadedEquipment }));
    } catch (err) {
      message.error(err);
    } finally {
      setState(s => ({ ...s, loading: false }))
    }
  }

  const renderLoadedEquipment = (style = {} as any) => {
    return (
      <div style={style}>
        {Object.keys(state.loadedEquipment).map(k => <EQInfo equipment={state.loadedEquipment[k]} />)}
      </div>
    )
  }

  useEffect(() => {
    getEqFromLocalStorage().then(loadEquipment);
  }, []);

  useEffect(() => {
    if (loggedIn && user) {
      const sState = { auth: { user } } as AppState;

      let passes = true;

      if (userHasRole(5, sState)) {
        props.history.push('/dashboard/manufactureProcess');
        passes = false;
      } else if (userHasRole([6, 7], sState)) {
        props.history.push('/dashboard');
        passes = false;
      }

      if (!passes && !displayedMessage) {
        displayedMessage = true;
        message.error('Equipment Registration is not available on your user account.');
      }
    }
  }, [loggedIn]);

  const showEqRegistration = loggedIn && (hasOrg || userHasRole([0, 1], { auth: { user } } as AppState));

  return (
    <div css={css(styles.container, (state.authState.showSignup || showEqRegistration || loggedIn) && `justify-content: flex-start;`)}>
      <div style={{ marginTop: 5 }}>
        {state.loading && <Loading />}
      </div>
      {showEqRegistration
        ? <EqRegistrationForm {...state} {...{ renderLoadedEquipment }} />
        : loggedIn
          ? <OrgCreate renderLoadedEquipment={renderLoadedEquipment} equipment={firstKey(state.loadedEquipment)} />
          : <Auth renderLoadedEquipment={renderLoadedEquipment} onStateChange={sa => setState(s => ({ ...s, authState: sa }))} />}
    </div>
  )
})

const EQInfo = (props: any) => {
  const { equipment } = props;

  const fields = [
    { title: 'Equipment Serial Number', val: get(equipment, 'equipmentSN') },
    { title: 'Model', val: get(equipment, 'modelId') },
  ]

  return (
    <div style={{ backgroundColor: colors.highlight, width: '100%', color: colors.white, padding: 10 }}>
      {fields.map(f => <div>{`${f.title}`}<b>{`: ${f.val}`}</b></div>)}
    </div>
  )
}