/** @jsx jsx */
import React, { useMemo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { InstructionsPage } from '../interfaces/model';
import { Button, Icon, Modal } from 'antd';
import getConfig from "../../config"

const styles = {
  imageWrapper: `
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  `,
  imageContainer: `
    align-items: center;
    justify-content: center;
    height: 142px;
    display: inline-block;
    flex: 0 1 100px;

    img {
      transition: 0.5s all;
      opacity: 0.0;
      max-height: 100%;
    }  
    
    .anticon {
      position: absolute;
      transition: 0.5s all;
      opacity: 1.0;
      font-size: 40px;
    }
  `,
  hasLoaded: `
    img {
      opacity: 1.0;
    }

    .anticon {
      opacity: 0.0;
    }
  `,
};

export const ViewInstructionsComponent: React.FC<{
  instructions: InstructionsPage[],
  visible?: boolean,
  onClose?: () => void,
}> = props => {
  const { instructions, visible, onClose } = props;

  const [currentPage, setCurrentPage] = useState<number>(0);
  // TODO: currently hard-coded for max of 4 images, which is only ensured by client-side requirement in edit-instructions form
  const [imagesLoaded, setImagesLoaded] = useState<boolean[]>([false, false, false, false]);

  const page = useMemo(() => instructions[currentPage], [instructions, currentPage]);

  const images = useMemo<string[]>(() => page.images ? page.images.map(img => `${getConfig().model_photos}${img}`) : [],
    [page]);

  const modalFooter = useMemo(() => {
    const pageBack = () => {
      setCurrentPage(currentPage - 1);
    };

    const pageBackEnabled = currentPage > 0;
    const pageForward = () => {
      setCurrentPage(currentPage + 1);
    }

    const pageForwardEnabled = instructions && (currentPage < (instructions.length - 1));
    const buttons = [<Button key="back" onClick={pageBack} disabled={!pageBackEnabled}>Back</Button>];
    buttons.push(<Button key="next" onClick={pageForward} disabled={!pageForwardEnabled}>Forward</Button>);
    return buttons;
  }, [page, currentPage, instructions]);

  return <Modal
    visible={visible}
    title={page.title || ''}
    footer={modalFooter}
    closable={true}
    afterClose={() => setCurrentPage(0)}
    onCancel={onClose}
  >
    <div className='image-wrapper' css={css(styles.imageWrapper)}>
      {images.map((image, i) => <div css={css(styles.imageContainer, imagesLoaded[i] && styles.hasLoaded)}>
        <img src={image} onLoad={(() => {
          const newImagesLoaded = [...imagesLoaded];
          newImagesLoaded[i] = true;
          setImagesLoaded(newImagesLoaded);
        })} />
        {imagesLoaded[i] && <Icon type="loading" />}
      </div>)}
    </div>
    {page.body || <div css={css(`font-style: italic;`)}>No description provided</div>}
  </Modal>
};