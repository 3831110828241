/** @jsx jsx */
import './manage-model.css';
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { sortByKey, getSensorsForEquipmentType, modelImageSource, ensureArray } from '../../utils';
import { AppState } from '../../app.state';
import { get } from 'lodash';
import { _Model, EquipmentTypeName, InstructionsPage, Model, Threshold } from '../../_shared/interfaces/model';
import { deleteModel, updateModel } from '../../_shared/services/manage-models.service';
import FormTitle from '../../_shared/form-title';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import { Icon, Modal, Table } from 'antd';
import { Row } from '../../_shared/row';
import SharedStyles from '../../_shared/styles';
import ModelImage from '../../_shared/model-image';
import { ViewInstructionsComponent } from '../../_shared/view-instructions/view-instructions.component';
import { getEquipmentForModel } from '../../_shared/services/manage-model.service';
import colors from '../../_shared/colors';

interface IProps extends RouteComponentProps {
  sState: AppState;
  updateModels: () => Promise<void>;
}

interface thresholdRow {
  key: string,
  sensorName: string,
  minorLower?: number;
  majorLower?: number;
  minorUpper?: number;
  majorUpper?: number;
  durationLower?: number;
  durationUpper?: number;
  suffix: string;
};

const COLS = [
  {
    title: 'Sensor ID',
    dataIndex: 'key',
  },
  {
    title: 'Sensor',
    dataIndex: 'sensorName',
  },
  {
    title: 'Units',
    dataIndex: 'suffix',
  },
  {
    title: 'Minor Lower',
    dataIndex: 'minorLower',
    editable: true,
  },
  {
    title: 'Major Lower',
    dataIndex: 'majorLower',
    editable: true,
  },
  {
    title: 'Minor Upper',
    dataIndex: 'minorUpper',
    editable: true,
  },
  {
    title: 'Major Upper',
    dataIndex: 'majorUpper',
    editable: true,
  },
  {
    title: 'Duration (Lower)',
    dataIndex: 'durationLower',
    editable: true,
  },
  {
    title: 'Duration (Upper)',
    dataIndex: 'durationUpper',
    editable: true,
  }
];

const ImagePanel: React.FC<{
  model?: Model,
}> = props => {
  // placeholder, might grow more complex
  const { model } = props;
  const showModelImage: boolean = !!modelImageSource(model);
  if (!model || !showModelImage) return null;
  return (<div className='image-panel panel'>
    <div>
      <div className='panel-title'>Model Image</div>
      <ModelImage css={css('height: 200px; align-items: start;')} model={model} imageName='' />
    </div>
    {model.type.includes('chair') && <div>
      <div className='panel-title'>Chair Mount</div>
      <ModelImage css={css('height: 200px; align-items: start;')} model={model} imageName='chairMount0' />
    </div>}
  </div>);
  //return <ModelImage className='image-panel panel' css={css('height: 200px; align-items: start;')} model={model} />
};

const InfoPanel: React.FC<{
  model?: Model,
}> = props => {
  const { model } = props;
  if (!model) {
    return <div className='info-panel panel'>
      No Model
    </div>
  }

  const typeName = EquipmentTypeName(model.type);
  const showHeads = model.type.includes('compressor');
  const showHardware = ['6400'].includes(get(model, 'id', ''));
  return (
    <div className='info-panel panel'>
      <div className='panel-title'>Model Info</div>
      <div style={{ paddingLeft: '5px' }}>
        <Row>
          <span className='row-name'>Model ID: </span> {get(model, 'id', '')}
        </Row>

        {model.description && <Row>
          {model.description}
        </Row>}

        <Row>
          <span className='row-name'>Manufacturer: </span> {get(model, 'manufacturer', '')}
        </Row>

        {model.family &&
          <Row>
            <span className='row-name'>Family: </span> {get(model, 'family', '')}
          </Row>}

        <Row>
          <span className='row-name'>Type: </span> {typeName}
        </Row>

        {showHeads && <Row>
          <span className='row-name'>Heads: </span> {model.heads}
        </Row>
        }

        {showHardware &&
          <div>
            <Row>
              <span className='row-name'>Control Board Firmware: </span> {ensureArray(get(model, 'control_board_fw', '')).join(', ')}
            </Row>
            <Row>
              <span className='row-name'>Control Board Hardware: </span> {ensureArray(get(model, 'control_board_hw', '')).join(', ')}
            </Row>
            <Row>
              <span className='row-name'>Daughterboard Firmware: </span> {ensureArray(get(model, 'fw_version', '')).join(', ')}
            </Row>
          </div>}
      </div>
    </div>
  );
};

const InstructionsRow: React.FC<{
  page: InstructionsPage,
  onClick: () => void;
}> = props => {
  const { page, onClick } = props;
  const dType = page.body && page.body.length > 0 ? "check-circle" : "exclamation-circle";
  const iType = page.images && page.images.length > 0 ? "check-circle" : "exclamation-circle";
  return (<tr className='instructions-row' >
    <td className='instructions-page-title'>{page.title}</td>
    <td className='instructions-page-check'><Icon theme='filled' type={dType} /> </td>
    <td className='instructions-page-check'><Icon theme='filled' type={iType} /> </td>
    <td className='instructions-page-check'><Icon theme='outlined' type='edit' onClick={onClick} style={{ color: colors.highlight }} /> </td>
  </tr>)
};

const InstructionsPanel: React.FC<{
  model?: Model,
  previewInstructions: () => void,
  goToPage: (page: number) => void,
}> = props => {
  const { model, previewInstructions, goToPage } = props;

  if (!model) return null;
  if (!model.instructions || model.instructions.length !== 4) return (
    <div className='instructions-panel panel'>
      <div className='panel-title'>Installation Instructions</div>
      <Button
        outline
        title='Configure Instructions'
        onClick={() => goToPage(0)}
      />
    </div>
  );
  return (
    <div className='instructions-panel panel'>
      <div className='panel-title'>Installation Instructions</div>
      <Button
        outline
        title='Preview Instructions'
        onClick={previewInstructions} />
      {model.instructions && <table className='instructions-tbl'>
        <thead>
          <tr>
            <th>Steps</th>
            <th style={{ textAlign: 'center' }}>Description</th>
            <th style={{ textAlign: 'center' }}>Image(s)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {model.instructions.map((p, i) => InstructionsRow({ page: p, onClick: () => goToPage(i) }))}
        </tbody>
      </table>}
    </div>
  )
};

const ThresholdsPanel: React.FC<{
  loading: boolean,
  thresholdData: thresholdRow[],
}> = props => {
  const { loading, thresholdData } = props;
  return (
    <div className='thresholds-panel'>
      <div className='panel-title'>Alert Thresholds</div>
      <Table
        rowKey={(t: thresholdRow) => t.key}
        size='small'
        dataSource={thresholdData}
        columns={COLS}
        loading={loading}
        pagination={false}
        rowClassName={(_, index) => `tbl-list-row-${index % 2}`}
      />
    </div>
  )
};

export class _ManageModelComponent extends Component<IProps> {
  state = {
    show: true,
    loading: true,
    isEdit: false,
    error: undefined,
    success: undefined,

    model: undefined as Model | undefined,
    thresholdData: [] as thresholdRow[],
    instructionsVisible: false,
    deleting: false,
  };

  componentDidMount = async () => {
    const { history } = this.props;
    const model = get(history, 'location.state.model');

    this.setState(
      {
        model,
      },
      this.retrieveData
    );
  };
  findModelInSState = () => {
    const { sState } = this.props;
    const modelId = get(this.state, 'model.id');
    const models = get(sState, 'dash.models', []) as Model[];
    return new _Model(models.find(m => m.id === modelId) as Model);
  };
  retrieveData = async () => {
    try {
      this.setState({
        loading: true,
        deleting: true
      });

      await this.props.updateModels();

      const { sState } = this.props;
      const model = this.findModelInSState();

      const allSensors = getSensorsForEquipmentType(model.type, sState);

      const thresholdData = allSensors
        .map(sensor => {
          const sensorId = sensor.id;
          const thresh = get(model.thresholds, sensorId, undefined) as Threshold | undefined;
          const row = {
            key: sensorId,
            sensorName: get(sensor, 'name', ''),
            suffix: get(sensor, 'suffix', ''),
          } as thresholdRow;

          if (thresh) {
            row.majorLower = thresh.majorLower;
            row.minorLower = thresh.minorLower;
            row.minorUpper = thresh.minorUpper;
            row.majorUpper = thresh.majorUpper;
            row.durationLower = thresh.durationLower;
            row.durationUpper = thresh.durationUpper;
          }

          return row;
        })
        .sort((a, b) => sortByKey(a, b, 'id'));

      this.setState({
        show: true,
        loading: false,
        deleting: false,
        thresholdData,
        model,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        error: err.message,
        loading: false,
        deleting: false,
      });
    }
  };

  showDeleteModal = async () => {
    if (!this.state || !this.state.model) return;

    const cancel = () => {
      Modal.destroyAll();
      this.setState({ loading: false, deleting: false });
    }

    const ok = async () => {
      if (!this.state.model) {
        cancel();
        return;
      }
      try {
        this.setState({ loading: true });
        await deleteModel(this.state.model)
        this.back();
      } catch (err) {
        console.error(err);
        Modal.destroyAll();
        this.setState({
          error: err.message,
          loading: false,
        });
      }
    }

    try {
      this.setState({ loading: true, deleting: true });
      const equipment = await getEquipmentForModel(this.state.model.id);
      if (equipment.length > 0) {
        Modal.confirm({
          title: 'Cannot Delete Model',
          content: `This model is currently in use by ${equipment.length} equipments.`,
          okButtonProps: { disabled: true },
          okType: 'danger',
          onCancel: cancel,
        });
      } else {
        Modal.confirm({
          title: 'Delete Model?',
          content: 'This cannot be undone.',
          okText: 'Delete',
          onOk: ok,
          onCancel: cancel,
          okType: 'danger'
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        error: err.message,
        loading: false,
        deleting: false,
      });
    }
  };

  editModel = (model: Model | undefined, goToPage?: number) => {
    this.props.history.push('/dashboard/editModel', {
      model,
      backText: `< Back to Model Details`,
      editingModel: true,
      goToPage
    });
  };

  changeModel = async (d = {}) => {
    const model = this.findModelInSState();
    const newmodel = { ...model, ...d };
    await updateModel(newmodel);
    this.retrieveData();
  };
  back = () => {
    this.props.history.goBack();
  };

  render() {
    const { thresholdData, loading, model, deleting } = this.state;

    const isAerasOne: boolean = get(model, 'type', '').includes('aerasone');

    return (
      <div className='manage-model-container'>
        <div css={css(SharedStyles.row, 'margin-bottom:5px;justify-content: space-between;')}>
          <Link
            css={css('margin-bottom: 15px; width: 150px')}
            onClick={this.back}
          >
            {'< Back to Manage Models'}
          </Link>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              columnGap: '5px',
            }}
          >
            <Button
              title="Delete"
              onClick={this.showDeleteModal}
              disabled={deleting}
              outline
            />
            <Button
              loading={loading}
              title="Refresh"
              onClick={this.retrieveData}
              outline
            />
            <Button
              title="Edit"
              onClick={() => this.editModel(this.state.model)}
            />
          </div>
        </div>
        <Row>
          <FormTitle size='16px' css={css('margin-bottom: 10px;')}>
            {get(model, 'name')}
          </FormTitle>
        </Row>
        <div className='panels-grid'>
          <div className='left-panels'>
            <ImagePanel model={model} />
          </div>
          <div className='right-panels'>
            <InfoPanel model={model} />
            {isAerasOne && <InstructionsPanel
              model={model}
              previewInstructions={() => this.setState({ instructionsVisible: true })}
              goToPage={(page: number) => this.editModel(model, page)} />}
            {isAerasOne && <ThresholdsPanel loading={loading} thresholdData={thresholdData} />}
          </div>
        </div>

        {this.state.model && this.state.model.instructions && <ViewInstructionsComponent
          instructions={this.state.model!.instructions}
          visible={this.state.instructionsVisible}
          onClose={() => this.setState({ instructionsVisible: false })}
        />}

      </div>
    );
  }
}

export const ManageModelComponent = withRouter(_ManageModelComponent);
