import axios, { Method } from 'axios';
import { get } from 'lodash';
import CONFIG, { IAppConfig, ENVS, getUrl } from '../../config';
import { instance } from './instance';
import { Equipment } from '../interfaces/equipment';
import { createEventSource } from 'eventsource-client';

const config: IAppConfig = CONFIG(process.env.ENV_STAGE as ENVS);

export const getHardwareVersion = async (deviceId: string) => {
  const access_token = config.particle_key;
  // tslint:disable-next-line: max-line-length
  const url = `https://api.particle.io/v1/products/9783/devices/${deviceId}/HW_VER?access_token=${access_token}`;
  const options = {
    method: 'GET' as Method,
    url,
  };
  try {
    const response = await axios(options);
    return get(response, 'data.result') as string;
  } catch (error) {
    console.warn(error);
  }
};

interface ExistsResult {
  exists: boolean;
};

export const exists = (deviceSerial: string) => {
  return instance.get<ExistsResult>(`/installation/exists/${deviceSerial}`);
}

interface ReachableResult {
  ok: boolean;
  online: boolean;
};

export const reachable = (deviceId: string) => {
  return instance.get<ReachableResult>(`/installation/reachable/${deviceId}`);
};

interface DeviceInfoResult {
  ok: boolean;
  device_id: string;
  iccid: string;
}

export const deviceInfo = (deviceSerial: string) => {
  return instance.get<DeviceInfoResult>(`/installation/deviceInfo/${deviceSerial}`);
};

export const setType = (
  deviceId: string,
  index: number,
  type?: string,
) => {
  const typeParam = type === "aerasone_compressor"
    ? "compressor"
    : type === "aerasone_vacuum"
      ? "vacuum"
      : "";

  return instance.post<CmdResult>(`/installation/setType/${deviceId}/${index}`, { type: typeParam });
}

interface CmdResult {
  id: string,
  name: string,
  connected: boolean,
  return_value: number,
};

export const requestConfig = (
  deviceId: string,
  index: number,
) => {
  return instance.get<CmdResult>(`/installation/getConfig/${deviceId}/${index}`);
}

export const requestSensorData = (deviceId: string, index: number) => {
  return instance.get<CmdResult>(`/installation/getData/${deviceId}/${index}`);
}

/*
Event stream samples:
event: keepalive
data:

event: ai_config
data: {"data":"{\"seq\":10,\"time\":1737398463,\"eq\":0,\"equipment_type\":\"compressor\",\"trigger\":\"function;\",\"device_on\":[false,1514835144,\"indicator\"],\"motor_running\":false,\"last_regen_start\":\"Thu Jan  1 00:00:00 1970\",\"last_degen_start\":\"Thu Jan  1 00:00:00 1970\",\"runtime\":413943812,\"equipment_alert\":false,\"ambient_inhg\":29.6793}","ttl":60,"published_at":"2025-01-20T18:41:23.651Z","coreid":"e00fce6862506aaef83a1d2a","userid":"","version":2058,"public":false,"productID":22627}

event: ai_sensor_instant
data: {"data":"{\"seq\":11,\"time\":1737398504,\"CC\":0,\"TP\":0,\"AT\":66.7,\"AH\":31.8,\"bars\":2,\"PCBT\":69.1,\"BP\":-1,\"PS\":0,\"eq\":0}","ttl":60,"published_at":"2025-01-20T18:41:59.057Z","coreid":"e00fce6862506aaef83a1d2a","userid":"","version":2058,"public":false,"productID":22627}
*/


type DeviceOn = [boolean | number | string];

interface BaseData {
  seq: number,
  time: number, // UNIX timestamp
  eq: number,
  published_at: string, // ISO format
  coreid: string,
  version: number,
};

export interface A1ConfigData extends BaseData {
  equipment_type: string;
  trigger: string;
  device_on: DeviceOn; // [boolean, UNIX timestamp (number), detection method (string)]
  motor_running: boolean;
  last_regen_start?: string; // readable
  last_degen_start?: string; // readable
  runtime: number;
  equipment_alert: boolean;
  ambient_inhg: number;
};

export interface A1SensorData extends BaseData {
  CC: number;
  TP?: number;
  IP?: number;
  AT: number;
  AH: number;
  bars: number;
  PCBT: number;
  BP: number;
  PS: number;
};

export const eventStreamUrl = (deviceId: string, index: number) => {
  return `${getUrl()}/installation/events/${deviceId}/${index}`;
}