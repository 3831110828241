/** @jsx jsx */


import { jsx } from '@emotion/core';
import { get } from "lodash";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { isLoggedIn, userHasRole } from "../../utils";
import { AppState } from "../../app.state";
import { useEffect, useState } from "react";
import { message } from 'antd';
import { Loading } from "../../_shared/icon";
import { Auth } from "../auth";
import { A1RegistrationForm } from "./a1-registration-form.component";
import { Equipment } from "../../_shared/interfaces/equipment";
import { A1InstallationInstructions } from "./a1-installation-instructions.component";
import { Model } from "../../_shared/interfaces/model";
import { A1InstallationTest } from "./a1-installation-test.component";
import { A1InstallationConfirmation } from "./a1-installation-confirmation.component";

import './a1-registration.css';
import { OrgCreate } from '../org';
import { createEquipment } from '../../_shared/services/manage-equipment.service';

let displayedMessage = false;

export const A1Registration = withRouter((props) => {
  const user = useSelector(s => get(s, 'auth.user'));
  const loggedIn = !!useSelector(s => isLoggedIn(s as AppState));
  const token = useSelector(s => get(s, 'auth.token'));
  const hasOrg = get(user, 'orgId');
  // main equipment reg uses authState.showSignup flag for CSS
  const [authState, setAuthState] = useState({});
  const [loading, setLoading] = useState(false);
  const [equipment, setEquipment] = useState<(Equipment | undefined)[]>([undefined, undefined]);
  const [models, setModels] = useState<(Model | undefined)[]>([undefined, undefined]);
  const [installed, setInstalled] = useState([false, false]);
  const [tested, setTested] = useState([false, false]);
  const [deviceId, setDeviceId] = useState('');

  useEffect(() => {
    if (loggedIn && user) {
      const sState = { auth: { user } } as AppState;

      let passes = true;
      /*
            if (userHasRole(5, sState)) {
              props.history.push('/dashboard/manufactureProcess');
              passes = false;
            } else if (userHasRole([6, 7], sState)) {
              props.history.push('/dashboard');
              passes = false;
            }
              */

      if (!passes && !displayedMessage) {
        displayedMessage = true;
        message.error('Equipment Registration is not available on your user account.');
      }
    }
  }, [loggedIn]);

  const showRegistration = loggedIn && (hasOrg || userHasRole([0, 1], { auth: { user } } as AppState));

  const registrationSubmit = (
    deviceId: string,
    newEquipment: (Equipment | undefined)[],
    newModels: (Model | undefined)[],
  ) => {
    console.log('Registration submit');
    if (newEquipment[0] === undefined) console.log('Equipment 0: undefined'); else console.log(`Equipment 0: ${JSON.stringify(newEquipment[0])}`);
    if (newEquipment[1] === undefined) console.log('Equipment 1: undefined'); else console.log(`Equipment 1: ${JSON.stringify(newEquipment[1])}`);
    if (newModels[0] === undefined) console.log('Model 0: undefined'); else console.log(`Model 0: ${JSON.stringify(newModels[0])}`);
    if (newModels[1] === undefined) console.log('Model 1: undefined'); else console.log(`Model 1: ${JSON.stringify(newModels[1])}`);

    if (newEquipment.length !== 2) return;
    setDeviceId(deviceId);
    setEquipment(newEquipment);
    if (!newEquipment[0] && !newEquipment[1]) {
      // clear
      setInstalled([false, false]);
      setTested([false, false]);
      setModels([undefined, undefined])
    } else {
      // initialize, marking installation flags true if equipment is not configured
      setInstalled([newEquipment[0] === undefined, newEquipment[1] === undefined]);
      setTested([false, false]);
      setModels(newModels);
    }

  };

  const [confirmError, setConfirmError] = useState('');
  const confirm = async () => {
    try {
      if (equipment[0] !== undefined) {
        await createEquipment(equipment[0]!);
      }
      if (equipment[1] !== undefined) {
        await createEquipment(equipment[1]!);
      }
    } catch (e) {
      console.error(e);
      setConfirmError(`Error creating equipment: ${e}`);
      return;
    }
    props.history.push("/dashboard");
  };

  return (<div className="a1-installation-container">
    {loading && <Loading />}
    {
      (!loggedIn)
        ? <Auth onStateChange={sa => setAuthState(sa)} destination='/a1registration' />
        : (!showRegistration)
          ? <OrgCreate />
          : (!equipment[0] && !equipment[1])
            ? <A1RegistrationForm register={registrationSubmit} />
            : (!installed[0])
              ? <A1InstallationInstructions
                key="a1ii0"
                model={models[0]}
                equipment={equipment[0]}
                continue={() => setInstalled([true, installed[1]])}
              />
              : (!installed[1])
                ? <A1InstallationInstructions
                  key="a1ii1"
                  model={models[1]!}
                  equipment={equipment[1]!}
                  continue={() => setInstalled([true, true])}
                />
                : (!tested[0])
                  ? <A1InstallationTest
                    key="a1it0"
                    model={models[0]}
                    confirm={() => setTested([true, tested[1]])}
                    token={token}
                    index={0}
                    deviceId={deviceId}
                  />
                  : (!tested[1])
                    ? <A1InstallationTest
                      key="a1it1"
                      model={models[1]}
                      confirm={() => setTested([true, true])}
                      token={token}
                      index={1}
                      deviceId={deviceId}
                    />
                    : <A1InstallationConfirmation confirm={confirm} error={confirmError} />
    }
  </div>)
});