/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Input, Tag } from 'antd';
import { get } from 'lodash';
import colors from '../colors';
import { ensureArray } from '../../utils';

interface IProps {
  // tslint:disable-next-line: no-any
  placeholder?: string;
  onTagsChange?: (selected: string[]) => void;
  ref?: (comp: InputTagComponent) => void;
  disabled?: boolean;
  selected?: string[];
  limitReached?: boolean;
  useSelectedState?: boolean;
  hide: string[];
}

type Selected = { text: string; value: string };

interface IState {
  value: string;
  selected: Selected[];
}

export class InputTagComponent extends Component<IProps, IState> {
  static defaultProps: IProps;
  state: IState = {
    value: '',
    selected: ensureArray(this.props.selected || []).map(s => ({
      value: get(s, 'value', s),
      text: get(s, 'text', s),
    })),
  };
  tagChange = () => {
    if (this.props.onTagsChange) {
      this.props.onTagsChange(this.state.selected.map(s => s.value));
    }
  };
  onSelect = () => {
    const value = this.state.value;
    if (!value) { return }
    this.setState({ value: '', selected: [...this.state.selected, { value, text: value }] }, this.tagChange)
  };
  removeSelected = (removedTag: string) => {
    const { selected } = this.state;

    this.setState(
      {
        selected: selected.filter(t => t.value !== removedTag),
      },
      this.tagChange
    );
  };
  //@ts-ignore
  onChange = (e) => {
    const value = get(e, 'target.value');
    this.setState({ value: value as string });
  };
  clearTags = () => {
    this.setState({ selected: [] });
  };
  componentDidMount = () => {
    if (this.props.ref) {
      this.props.ref(this);
    }
  };
  componentDidUpdate(op: IProps) {
    if (op.selected !== this.props.selected) {
      const _selected =
        this.state.selected.length > 0 && this.props.useSelectedState
          ? this.state.selected
          : ensureArray(this.props.selected || this.state.selected).map(s => ({
              value: get(s, 'value', s),
              text: get(s, 'text', s),
            }));
      this.setState({
        selected: _selected,
      });
    }
  }
  render() {
    const { value, selected } = this.state;
    const { placeholder, disabled, limitReached } = this.props;
    const props = {
      placeholder,
      value,
      onSearch: this.onSelect,
      onChange: this.onChange,
      disabled: disabled || limitReached,
    };

    return (
      <div
        css={css`
          width: 100%;
        `}
      >
        <Input.Search css={css`width: 100%;`} enterButton={"+"} {...props} />
        <div
          css={css`
            margin-top: 5px;
          `}
        >
          {selected.map(sel => {
            return (
              <Tag
                css={css`
                  margin-bottom: 5px;
                `}
                color={colors.tagDark}
                key={sel.value}
                closable={!disabled}
                onClose={() => this.removeSelected(sel.value)}
              >
                {sel.text}
              </Tag>
            );
          })}
        </div>
      </div>
    );
  }
}

InputTagComponent.defaultProps = {
  placeholder: 'Enter Tags...',
  onTagsChange: () => 0,
  useStateSelected: true,
  hide: [],
} as IProps;
