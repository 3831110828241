/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useEffect, useMemo, useState } from "react";
import { Equipment } from "../../_shared/interfaces/equipment";
import { Button } from "../../_shared/button";
import { A1ConfigData, A1SensorData, eventStreamUrl, reachable, requestConfig, requestSensorData, setType } from "../../_shared/services/hardware.service";
import { EquipmentType, Model } from "../../_shared/interfaces/model";
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { createEventSource, EventSourceMessage } from 'eventsource-client';
import './a1-registration.css'

type TestState = "reachable" | "config" | "test";

type TaskFn = (setSucceeded: (s: boolean) => void, setError: (e: string) => void) => Promise<void>;
interface ITestProps {
  deviceId: string,
  index: number,
  task: TaskFn,
  proceed: () => void,
  initialPrompt: string,
  successPrompt: string,
}

const TestComponent = (props: ITestProps) => {
  const { task, proceed, initialPrompt, successPrompt } = props;

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState('');

  const className = 'a1-installation-test-container';

  useEffect(() => {
    task(setSucceeded, setError);
  }, []);

  if (error) {
    return <div className={className}>
      {error}
      <Button title={"Retry"} onClick={() => task(setSucceeded, setError)} />
    </div>;
  }

  if (!succeeded) {
    return <div className={className}>
      <div>{initialPrompt}</div>
    </div>
  };

  return <div className={className}>
    <div>{successPrompt}</div>
    <Button title={"Continue"} onClick={proceed} />
  </div>;
}

interface ITestStepProps {
  deviceId: string,
  index: number,
  type?: EquipmentType,
  proceed: () => void,
  token: string,
};

const Reachable = (props: ITestStepProps) =>
  TestComponent({
    ...props,
    initialPrompt: "Testing connection...",
    successPrompt: "Connection succeeded!",
    task: async (setSucceeded: (s: boolean) => void, setError: (e: string) => void) => {
      setSucceeded(false);
      setError('');
      try {
        const result = await reachable(props.deviceId);
        if (result.status != 200) {
          setError(`Connection failed: ${result.statusText}`);
        }
        else if (!result.data.ok) {
          if (!result.data.online) {
            setError("Connection failed: device is offline");
          } else {
            setError(`Connection failed.`)
          }
        } else {
          setSucceeded(true);
        }
      } catch (e) {
        setError(`Connection failed: ${e}`);
      }
    }
  })

const Configure = (props: ITestStepProps) =>
  TestComponent({
    ...props,
    initialPrompt: "Configuring device...",
    successPrompt: "Device configured!",
    task: async (setSucceeded: (s: boolean) => void, setError: (e: string) => void) => {
      setSucceeded(false);
      setError('');
      try {
        const result = await setType(props.deviceId, props.index, props.type);
        if (result.status != 200) {
          if (!result.data.connected) {
            setError('Device is offline. Please reconnect device and try again.');
          } else {
            setError('Configuration failed with unknown error.');
          }
        } else if (result.data.return_value !== 0) {
          setError(`Configuration failed with error ${result.data.return_value}.`);
        }
        else {
          setSucceeded(true);
        }
      } catch (e) {
        setError(`Failed to configure: ${e}`);
      }
    }
  })

const ConfigDataComponent = (props: { data?: A1ConfigData }) => {
  const { data } = props;
  if (!data) {
    return <div>No config data received yet</div>;
  }

  return <div>
    <div>Power: {data.device_on[0] ? "On" : "Off"}</div>
    <div>Type: {data.equipment_type}</div>
    <div>Motor running: {data.motor_running ? "Yes" : "No"}</div>
    <div>Ambient pressure: {data.ambient_inhg} inHg</div>
  </div>;
};

const SensorDataComponent = (props: { data?: A1SensorData }) => {
  const { data } = props;
  if (!data) {
    return <div>No sensor data received yet</div>;
  }

  return <div>
    <div>Chassis Current: {data.CC} A</div>
    <div>Ambient Temperature: {data.AT}° F</div>
    <div>Ambient Humidity: {data.AH}%</div>
    {data.IP !== undefined && <div>Vacuum Level: {data.IP} inHg</div>}
    {data.TP !== undefined && <div>Tank Pressure: {data.TP} psi</div>}
  </div>;
};

const Test = (props: ITestStepProps) => {
  const { deviceId, index, proceed, token } = props;
  const [stats, setStats] = useState({});
  const [error, setError] = useState('');

  const [config, setConfig] = useState(undefined as A1ConfigData | undefined);
  const [sensorData, setSensorData] = useState(undefined as A1SensorData | undefined);

  const onMessage = (props: EventSourceMessage) => {
    const { data, event } = props;
    console.log(`Event: ${event}, data: ${data}`);
    if (event === "ai_config") {
      const parsedData = JSON.parse(data);
      setConfig(JSON.parse(parsedData.data) as A1ConfigData);
    } else if (event === "ai_sensor_instant") {
      const parsedData = JSON.parse(data);
      setSensorData(JSON.parse(parsedData.data) as A1SensorData);
    } else if (event === "error") {
      const err = `Error in event stream: ${data}`;
      console.error(err);
      setError(err);
    }
  };

  useEffect(() => {
    //const eventSource = eventStream(equipment, token);
    const eventSource = createEventSource({
      url: eventStreamUrl(deviceId, index),
      headers: { Authorization: `Bearer ${token}` },
      onMessage
    })

    try {
      requestConfig(deviceId, index);
      requestSensorData(deviceId, index);
    } catch (e) {
      const err = `Error requesting data: ${e}`;
      console.error(err);
      setError(err);
    }

    return () => {
      eventSource.close();
    }
  }, []);

  return (<div className='a1-installation-interactive'>

    <div>Equipment Test</div>
    <div>Please turn the equipment on and off and verify the displayed sensors.</div>

    <div>
      <div>Config Data</div>
      <ConfigDataComponent data={config} />
      <Button title="Refresh Config" onClick={() => requestConfig(deviceId, index)} />
    </div>

    <div>
      <div>Sensor Data</div>
      <SensorDataComponent data={sensorData} />
      <Button title="Refresh Sensors" onClick={() => requestSensorData(deviceId, index)} />
    </div>

    {error && <div>{error}</div>}

    <Button title="Proceed" onClick={proceed} />
  </div>)
};

interface A1InstallationTestProps {
  equipment?: Equipment;
  model?: Model;
  confirm: () => void;
  token: string;
  deviceId: string;
  index: number;
};

export const A1InstallationTest: React.FC<A1InstallationTestProps> = (props: A1InstallationTestProps) => {
  const { equipment, confirm, model, token } = props;

  const [testState, setTestState] = useState<TestState>("reachable");
  const type = useMemo(() => model ? model.type : undefined, [model]);


  return <div className='a1-installation-test-wrapper'>
    <div className='a1-registration-header'>Configuring Equipment {props.index}</div>
    {testState === "reachable" && <Reachable
      type={type}
      deviceId={props.deviceId}
      index={props.index}
      proceed={() => setTestState("config")}
      token={token}
    />}
    {testState === "config" && <Configure
      deviceId={props.deviceId}
      index={props.index}
      type={type}
      proceed={() => {
        if (!!model) {
          setTestState("test");
        } else {
          confirm();
        }
      }}
      token={token}
    />}
    {testState === "test" && <Test
      deviceId={props.deviceId}
      index={props.index}
      type={type}
      proceed={confirm}
      token={token}
    />}
  </div>;
};