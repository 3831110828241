/** @jsx jsx */

import { jsx } from '@emotion/core';
import { get, orderBy, set, uniq } from "lodash";
import { Org } from "../../_shared/interfaces/org";
import { _Location, Location } from "../../_shared/interfaces/location";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
import { Row } from '../../_shared/row';
import { useSelector } from "react-redux";
import Logo from "../../_shared/logo";
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Model } from "../../_shared/interfaces/model";
import { FormComponentProps } from "antd/lib/form";
import { GetFieldDecoratorOptions, WrappedFormUtils } from "antd/lib/form/Form";
import { getOrgs } from "../../_shared/services/manage-orgs.service";
import { createLocation, getLocations } from "../../_shared/services/manage-locations.service";
import { getModels } from "../../_shared/services/manage-models.service";
import { userHasRole } from "../../utils";
import { AppState } from "../../app.state";
import { _EquipmentRecord, Equipment } from "../../_shared/interfaces/equipment";
import { User } from "../../_shared/interfaces/user";
import { deviceInfo, exists } from "../../_shared/services/hardware.service";
import { AddLocationModal } from '../add-location-modal.component';
import moment, { Moment } from 'moment';
import './a1-registration.css';

interface OrgLocationProps extends FormComponentProps {
  orgs?: Org[];
  orgId: string;
  setOrg: (orgId: string) => void;
  locations?: Location[];
  locationId: string;
  setLocation: (locationId: string) => void;
  disabled?: boolean;
  user: User;
  locationAdded: (locationId: string) => void;
  showOrg: boolean;
};

const OrgLocationComponent: React.FC<OrgLocationProps> = props => {
  const { orgs, orgId, setOrg, locations, locationId, setLocation, form, user, locationAdded, showOrg } = props;
  const disabled = props.disabled || false;

  const { getFieldDecorator } = form;

  const locDisabled = locations === undefined || locations.length === 0;

  const [locationModal, setLocationModal] = useState({});

  const addLocation = () => {
    let form: any;

    const close = () => {
      setLocationModal({});
    }

    setLocationModal({
      title: 'Add Location',
      visible: true,
      formProps: {
        registerForm: (f: any) => form = f,
      },
      footer: null,
      onCancel: () => {
        close()
      },
      onSubmit: () => {
        form.validateFieldsAndScroll(async (err: any, vals: any) => {
          if (err) {
            return;
          }

          try {
            const loc = new _Location(vals);
            loc.orgId = orgId;
            loc.createdBy = user.email;
            loc.updatedBy = user.email;
            const new_loc = await createLocation(loc);
            close();
            locationAdded(new_loc.id);
          } catch (err) {
            console.error(err);
          }
        });
      }
    })
  }

  const filteredOrgs = orgs !== undefined
    ? orderBy(orgs.filter(o => o.orgType === 1).map(o => ({ title: o.name, value: o.id })), 'title', 'asc')
    : [];

  const orgDecoratorOptions: GetFieldDecoratorOptions = {
    rules: [{ required: true, message: 'Dental Organization is required' }],
    initialValue: orgId,
  };

  const locDecoratorOptions: GetFieldDecoratorOptions = {
    rules: [{ required: true, message: 'Location is required' }],
    initialValue: locationId,
  };

  const filteredLocations = useMemo(() => {
    if (locations === undefined) return [];
    return orderBy(locations.filter(l => l.orgId !== undefined && l.orgId === orgId), 'name', 'asc');
  }, [locations, orgId]);

  return (<div className="org-location-container">
    <Form.Item label='Organization' key='fi-org'>
      {getFieldDecorator('orgId', orgDecoratorOptions)(
        <Select
          showSearch
          filterOption={(inp: string, option: any) => get(option, 'props.children', '').toUpperCase().indexOf(inp.toUpperCase()) !== -1}
          onChange={(v: string) => {
            setOrg(v);
            form.setFieldsValue({ orgId: v });
          }}
          disabled={disabled || !showOrg}
        >
          <Select.Option key={0} value=''></Select.Option>
          {filteredOrgs.map((o, i) => (
            <Select.Option key={i + 1} value={o.value}>
              {o.title}
            </Select.Option>))}
        </Select>
      )}
    </Form.Item>
    <Form.Item label='Location' style={{ position: 'relative', /*width: 'calc(100% - 32px)'*/ }} key='fi-loc'>
      {getFieldDecorator('locationId', locDecoratorOptions)(
        <Select
          onChange={(v: string) => {
            form.setFieldsValue({ locationId: v });
            setLocation(v);
          }}
          disabled={locDisabled || disabled}
        >
          <Select.Option key={0} value=''></Select.Option>
          {filteredLocations.map((o, i) => (
            <Select.Option key={i + 1} value={o.id}>
              {o.name}
            </Select.Option>))}
        </Select>
      )}
      <div className="addLocationButton">
        <Button style={{}} disabled={locDisabled} icon="plus" onClick={addLocation} title="" />
      </div>
    </Form.Item>
    <AddLocationModal {...locationModal} />
  </div>);
};

interface IEquipmentConfig {
  type: "aerasone_compressor" | "aerasone_vacuum" | "";
  modelId: string;
  serial: string;
  eq: number;
  installDate?: Moment;
}

interface IEquipmentConfigProps extends IEquipmentConfig {
  setType: (type: "aerasone_compressor" | "aerasone_vacuum" | "") => void;
  models: Model[];
  setModelId: (modelId: string) => void;
  serial: string;
  setSerial: (serial: string) => void;
  form: WrappedFormUtils;
  setInstallDate: (installDate: Moment) => void;
};

const EquipmentConfigComponent: React.FC<IEquipmentConfigProps> = (props => {
  const {
    type, setType, models, setModelId, serial, setSerial, installDate, setInstallDate, form
  } = props;
  const { getFieldDecorator } = form

  const [manufacturer, setManufacturer] = useState('');

  const manufacturers = useMemo(() => {
    return uniq(models.map(m => m.manufacturer)).sort();
  }, [models]);

  const displayModels = useMemo(() => manufacturer === ''
    ? models
    : models.filter(m => m.manufacturer === manufacturer), [manufacturer]);

  console.log(`rendering equipment config, #mfrs = ${manufacturers.length}`);

  return <div className='equipment-config-container'>
    <div className='ec-title'>Equipment {props.eq}</div>
    <Form.Item label='Type' key={'fi-type' + props.eq}>
      {getFieldDecorator(`type_${props.eq}`, { initialValue: type })(
        <Select
          onChange={(value: string) => {
            console.log(`onChange type ${value}`)
            form.setFieldsValue({ [`type_${props.eq}`]: value });
            setType(value as "aerasone_compressor" | "aerasone_vacuum" | "");
          }}
        >
          <Select.Option key={0} value="">None Attached</Select.Option>
          <Select.Option key={1} value="aerasone_compressor">Compressor</Select.Option>
          <Select.Option key={2} value="aerasone_vacuum">Vacuum</Select.Option>
        </Select>
      )}
    </Form.Item>
    <Form.Item label='Manufacturer' key={'fi-mfr' + props.eq}>
      {getFieldDecorator(`mfr_${props.eq}`, { initialValue: manufacturer, rules: [{ required: !!type }] })(
        <Select
          onChange={(value: string) => {
            form.setFieldsValue({ [`mfr_${props.eq}`]: value });
            setManufacturer(value);
          }}
          value={manufacturer}
          disabled={type === ''}
        >
          <Select.Option key={0} value=""></Select.Option>
          {manufacturers.map((m, i) => (
            <Select.Option key={i + 1} value={m}>{m}</Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
    <Form.Item label='Model' key={'fi-modelid' + props.eq}>
      {getFieldDecorator(`modelId_${props.eq}`, { rules: [{ required: !!type }], validateTrigger: 'onBlur', initialValue: props.modelId })(
        <Select
          disabled={manufacturer === '' || type === ''}
          onChange={(value: string) => {
            form.setFieldsValue({ [`modelId_${props.eq}`]: value });
            setModelId(value);
          }}
        >
          <Select.Option key={0} value={""}></Select.Option>
          {displayModels.map((m, i) => (
            <Select.Option key={i + 1} value={m.id}>{m.id}</Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
    <Form.Item label='Serial Number' key={'fi-serial' + props.eq}>
      {getFieldDecorator(`serial_${props.eq}`, { rules: [{ required: !!type }], validateTrigger: 'onBlur', initialValue: props.serial })(
        <Input
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            form.setFieldsValue({ [`serial_${props.eq}`]: event.target.value });
            setSerial(event.target.value);
          }}
          disabled={type === ''}
        />
      )}
    </Form.Item>
    <Form.Item label='Install Date' key={'fi-installdate' + props.eq}>
      {getFieldDecorator(`installDate_${props.eq}`, { initialValue: props.installDate })(
        <DatePicker
          disabled={type === ''}
          onChange={(date: moment.Moment, dateString: string) => {
            setInstallDate(date);
            form.setFieldsValue({ [`installDate_${props.eq}`]: date });
          }}
          value={installDate}
        />
      )}
    </Form.Item>
  </div>;
});

const blankConfig = {
  type: "" as "aerasone_compressor" | "aerasone_vacuum" | "",
  modelId: "",
  serial: "",
};


export interface A1RegistrationProps {
  register: (
    deviceId: string,
    equipment: (Equipment | undefined)[],
    models: (Model | undefined)[],
  ) => void;
};

const _A1RegistrationForm: React.FC<A1RegistrationProps & FormComponentProps> = props => {
  const { form, register } = props;
  const { getFieldDecorator } = form;
  const user = useSelector(s => get(s, 'auth.user'));
  const [loading, setLoading] = useState(true);

  const [userOrg, setUserOrg] = useState(undefined as Org | undefined);
  const [orgs, setOrgs] = useState([] as Org[]);
  const [locations, setLocations] = useState([] as Location[]);
  const [orgId, setOrgId] = useState('');
  const [locationId, setLocationId] = useState('');

  const [compressorModels, setCompressorModels] = useState([] as Model[]);
  const [vacuumModels, setVacuumModels] = useState([] as Model[]);

  const [controllerSN, setControllerSN] = useState('');
  const [equipment, setEquipment] = useState<IEquipmentConfig[]>([
    {
      ...blankConfig,
      eq: 0,
    },
    {
      ...blankConfig,
      eq: 1,
    },
  ]);

  const [error, setError] = useState('');

  const loadItems = async () => {
    try {
      setLoading(true);
      const [loadOrgs, loadLocations, loadModels] = await Promise.all([
        getOrgs(),
        getLocations(),
        getModels(),
      ]);

      const userOrg = loadOrgs.find(o => o.id === user.orgId) as Org;
      setOrgs(userOrg && userOrg.orgType == 0 ? loadOrgs.filter(o => o.serviceOrgId == userOrg.id) : loadOrgs);
      setLocations(loadLocations);
      setOrgId(userOrg !== undefined ? userOrg.id : '');
      if (userOrg !== undefined) {
        form.setFieldsValue({ orgId: userOrg.id });
      }
      setCompressorModels(loadModels.filter(m => m.type === "aerasone_compressor"));
      setVacuumModels(loadModels.filter(m => m.type === "aerasone_vacuum"));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const changeEquipment = (eq: number, prop: string, value: any) => {
    console.log(`changeEquipment[${eq}] prop ${prop} value: ${value}`);
    const newEquipment = [...equipment];
    set(newEquipment[eq], prop, value);
    setEquipment(newEquipment);
  }

  useEffect(() => {
    loadItems();
  }, []);

  const showOrg = userOrg !== undefined && userOrg.orgType == 0 || userHasRole([0], { auth: { user } } as AppState)

  const onSubmit = useCallback(async (e: MouseEvent | FormEvent) => {
    setError('');

    const { validateFields } = form;

    e.preventDefault();
    try {
      const validateResult: { errs: any, vals: any[] } = await new Promise((resolve, reject) => {
        try {
          validateFields((errs, vals) => {
            console.log(`fields validated, errs: ${JSON.stringify(errs)}`);
            resolve({ errs, vals });
          });
        } catch (err) {
          reject(err);
        }
      });

      console.log(`validateResult: ${JSON.stringify(validateResult)}`);
      if (validateResult.errs) {
        console.log('validation failed');
        return;
      }

      // check if equipment already exists for this controller
      const existsResult = await exists(controllerSN);
      if (existsResult.data.exists) {
        message.error("Equipment already exists for this controller");
        console.log('controller SN already exists');
        return;
      }

      // validate Particle serial number and get device ID
      const deviceIdResult = await deviceInfo(controllerSN);
      if (!deviceIdResult.data.ok) {
        message.error("Couldn't fetch controller info");
        console.log("Couldn't fetch controller info");
        setError('Failed to retrieve device info. Please check Controller Serial #.');
        return;
      }

      const deviceId = deviceIdResult.data.device_id;

      // build Equipment records and pass them up
      const records = equipment.map((e, i) => {
        if (e.type) {
          return new _EquipmentRecord({
            deviceId: `${deviceId}_${i}`,
            controllerSerial: controllerSN,
            equipmentSN: e.serial,
            modelId: e.modelId,
            locationId: locationId,
            dentalOrgId: orgId,
            installDate: e.installDate ? e.installDate.toISOString() : undefined,
            name: e.serial,
            showOnDash: true,
          }) as Equipment;
        } else return undefined;
      });

      // pass instructions along
      const models = equipment.map((e, i) => {
        if (e.type === "aerasone_compressor") {
          return compressorModels.find(m => m.id === e.modelId);
        } else if (e.type === "aerasone_vacuum") {
          return vacuumModels.find(m => m.id === e.modelId);
        } else return undefined;
      });
      register(deviceId, records, models);
    } catch (err) {
      setError(err.Message);
      console.log(`Caught exception: ${err}`);
    } finally {
    }

  }, [register, equipment, form]);

  const hasFieldError = useCallback((): boolean => {
    const errors = form.getFieldsError();
    const hasErrors = (x: any) => {
      for (let key in x) {
        if (typeof (x[key]) == 'object') {
          if (hasErrors(x[key])) {
            return true;
          }
        } else if (x[key]) {
          return true;
        }
      }
      return false;
    };
    if (!errors) {
      return false;
    }
    return hasErrors(errors);
  }, [form]);

  console.log('rendering form');
  return (<div className="a1-registration-container">
    <Row center style={{ marginBottom: 10 }}><Logo /></Row>
    <p className="a1-registration-title">Equipment Registration</p>
    <Form>
      {<OrgLocationComponent
        orgs={orgs}
        orgId={orgId}
        setOrg={setOrgId}
        locations={locations}
        locationId={locationId}
        setLocation={l => {
          setLocationId(l);
        }}
        form={form}
        disabled={loading}
        user={user}
        showOrg={showOrg}
        locationAdded={async (locationId: string) => {
          try {
            const locations = await getLocations();
            setLocations(locations);
            setLocationId(locationId);
          } catch (e) {
            console.log(e);
          }
        }}
      />}
      <Form.Item label='Controller Serial #'>
        {getFieldDecorator('controllerSN', { rules: [{ required: true, message: 'Controller Serial # is required.' }], validateTrigger: 'onBlur' })(
          <Input
            value={controllerSN}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              form.setFieldsValue({ controllerSN: event.target.value });
              setControllerSN(event.target.value);
            }} />
        )}
      </Form.Item>
      <div className='equipment-configs-container'>
        {equipment.map((e, i) => (
          <EquipmentConfigComponent
            key={`ecc_${i}`}
            type={e.type}
            setType={t => changeEquipment(i, 'type', t)}
            models={e.type === 'aerasone_compressor'
              ? compressorModels
              : e.type === 'aerasone_vacuum'
                ? vacuumModels
                : []
            }
            setModelId={m => changeEquipment(i, 'modelId', m)}
            modelId={e.modelId}
            eq={i}
            serial={e.serial}
            setSerial={s => changeEquipment(i, 'serial', s)}
            form={form}
            installDate={e.installDate}
            setInstallDate={s => changeEquipment(i, 'installDate', s)}
          />
        ))}
      </div>
      {error && <div className='a1-error'>{error}</div>}
      <Button type="primary" htmlType='submit' onClick={onSubmit} disabled={hasFieldError()}>Continue</Button>
    </Form>
  </div>);
};

export const A1RegistrationForm = Form.create()(_A1RegistrationForm) as unknown as React.FC<A1RegistrationProps>;