/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core';
import { Component } from 'react';
import { AppState } from '../../app.state';
import { Alert, Icon, Switch, Modal, message } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import FormTitle from '../../_shared/form-title';
import SharedStyles from '../../_shared/styles';
import Link from '../../_shared/link';
import styles, { global } from './equipment-details.styles';
import {
  getEquipment,
  getEquipmentRecord,
  updateEquipment,
} from '../../_shared/services/manage-equipment.service';
import {
  _Equipment,
  EQ_TYPE,
  Equipment,
  EquipmentRecord,
  ETYPES,
} from '../../_shared/interfaces/equipment';
import { EquipmentStatus } from '../../_shared/interfaces/equipmentStatus';
import { Sidebar } from './sidebar.component';
import InfoRow, { ICol } from '../../_shared/info-row';
import _Icon from '../../_shared/icon';
import colors from '../../_shared/colors';
import { renderLabel } from '../../utils/mixins';
import { getLocation } from '../../_shared/services/manage-locations.service';
import { getOrg } from '../../_shared/services/manage-orgs.service';
import { Org } from '../../_shared/interfaces/org';
import { Location } from '../../_shared/interfaces/location';
import moment from 'moment';
import { TableList } from '../../_shared/table-list';
import { _Message } from '../../_shared/interfaces/message';
import {
  Service,
  _Service,
  ServiceSerial,
} from '../../_shared/interfaces/service';
import { _Publish } from '../../_shared/interfaces/publish';
import { Model, _Model } from '../../_shared/interfaces/model';
import { take, get, set, flatten, size, first, orderBy, keyBy, uniqBy } from 'lodash';
import {
  defaultDateFormat,
  errorDef,
  getDeviceStatusFromEquip,
  findModel,
  getTimeForPubAlert,
  userHasRole,
  getEquipMaint,
  convertDZNowToJSNow,
  getEquipConfig,
  guid,
  isPB,
  getTriggeredMaint,
  getAllDeviceIds,
  parseServiceByDate,
  TG_ROLES,
  generateTGDeviceCMD,
  getEquipmentType,
  deDupeServices,
  isHandpiece,
  findLatestPublish,
  getDebugStatusHandpiece,
  handpieceIsDueForService,
  wait,
  isChair,
  sortPublishByTimeOrDate,
  getEquipmentModel,
  getEqType,
  getSterilizerFlagTextFromArray,
  getServiceType,
  getRelevantAlertMap,
} from '../../utils';
import { HandpieceProfileUsageTable } from './handpieceProfileUsageTable.component';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import { ModalEvent } from '../../_shared/modal-event';
import { Button } from '../../_shared/button';
import { Sensor } from '../../_shared/interfaces/sensor';
import { ModalChart } from '../../_shared/modal-chart';
import { serviceType } from '../../_shared/lib/services';
import { getService } from '../../_shared/services/manage-services.service';
import ModelImage from '../../_shared/model-image';
import { getHistory } from '../../_shared/services/device-history.service';
import { MapOpts } from '../dashboard.actions';
import { getAlert, getMessage } from '../../utils/publish';
import { getTandemGroups } from '../../_shared/services/manage-tandem-groups.service';
import {
  TandemGroup,
  _TandemGroup,
} from '../../_shared/interfaces/tandemGroup';
import AddEquipToTGModal from './add-to-tgroup-modal.component';
import { SelectValue } from 'antd/lib/select';
import { renderFWErrorModal } from '../../_shared/fw-error-modal';
import {
  generateInfoRows,
  getRightTime,
  timeText,
  sensorColumnBuilder
} from './equipment-details-columns';
import { Publish } from '../../_shared/interfaces/publishData';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { createPublish } from '../../_shared/services/publish.service';
import { _size } from '../../utils/awaitAll';
import { ColumnProps } from 'antd/lib/table';
import { EquipPusherComponent } from '../../_shared/EquipPusher';

interface IProps extends RouteComponentProps {
  sState: AppState;
  // tslint:disable-next-line:no-any
  addToMap: (pubs: Publish[], opts?: MapOpts) => void;
  waitForNewStatistic: (eq: Equipment) => Promise<unknown>;
  waitForNewConfig: (eq: Equipment) => Promise<unknown>;
}

interface IState {
  loading: boolean;
  isLoading: boolean;
  error?: string;
  success?: string;
  equipment: Equipment;
  latestEquipmentPublishData?: EquipmentStatus;
  location?: Location;
  serviceOrg?: Org;
  dentalOrg?: Org;
  sensorsExpanded?: boolean;
  showAllMessages: boolean;
  showAllHistory: boolean;
  showAllReminders: boolean;
  serviceHistory: Service[];
  model?: Model;
  modalVisible: boolean;
  modalChart?: {
    visible: boolean;
    chosenSensor: string;
  };
  // tslint:disable-next-line:no-any
  selectedPublish?: Publish;
  loadingSensor: boolean;
  sendingCommand: boolean;
  alertsLoading: boolean;
  expandAlerts: boolean;
  expandMaint: boolean;
  expandService: boolean;
  tandemGroup: TandemGroup | undefined;
  tandemGroups: TandemGroup[] | undefined;
  availableTGroups: TandemGroup[] | undefined;
  addEquipToTGModalVisible: boolean;
  sensorInfo: ISensorInfo;
  deviceStatus: string;
  maintSks: string;
  totalChairCount?: number;
}

interface IServiceReminder {
  name: string;
  hours: { text: string; color: string };
  lastServiced: string;
}

export interface ISensorInfo {
  [key: string]: string | number;
}

export class _EquipmentDetailsComponent extends EquipPusherComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const equipment = this.getEquipment();

    this.state = {
      loading: false,
      isLoading: false,
      error: undefined,
      success: undefined,
      sensorsExpanded: !isHandpiece(equipment), //DEN-838 only handpieces should start collapsed
      equipment,
      location: undefined,
      serviceOrg: undefined,
      dentalOrg: undefined,
      showAllMessages: false,
      showAllHistory: false,
      serviceHistory: [],
      showAllReminders: false,
      model: undefined,
      modalVisible: false,
      selectedPublish: undefined,
      activeAlerts: [],
      publishes: [],
      sensor: undefined,
      sensorInstant: undefined,
      loadingSensor: false,
      config: undefined,
      sendingCommand: false,
      alertsLoading: false,
      expandAlerts: false,
      expandMaint: false,
      expandService: false,
      tandemGroup: undefined,
      tandemGroups: [],
      availableTGroups: [],
      addEquipToTGModalVisible: false,
      maint: undefined,
      sensorInfo: {},
      handpiecePresetsLocked: false,
      deviceStatus: equipment.deviceStatus,
      maintSks: '',
    } as IState;
  }

  getEquipment = (): Equipment => {
    const {
      sState: { dash },
    } = this.props;
    const { state } = this.props.history.location;

    if (this.state && this.state.equipment) {
      const equipment = get(dash, 'equipment', []).find(
        (eq: Equipment) => eq.sk === this.state.equipment.sk
      );
      return new _Equipment({ ...this.state.equipment, ...(equipment || {}) });
    } else if (state && state.equipment) {
      const equipment = get(dash, 'equipment', []).find(
        eq => eq.sk === state.equipment.sk
      );
      return new _Equipment(equipment);
    } else if (dash.equipment) {
      return new _Equipment(dash.equipment[0]);
    } else {
      return new _Equipment();
    }
  };
  updateEquipment = async () => {
    const { sState } = this.props;
    const me = get(sState, 'auth.user');
    const updatedEquipment = this.getEquipment();
    set(updatedEquipment, 'updatedAt', moment().toISOString());
    set(updatedEquipment, 'updatedBy', me.userId);
    await updateEquipment(this.getEquipment());
  };
  trackEvent = (event = '', data = {}) => {
    trackEvent(event, { ...data, equipment: this.getEquipment() });
  };
  retrieveLatestSensor = async (refreshData = true) => {
    const e = this.getEquipment();
    var { deviceStatus } = this.state;

    if (!e.deviceId) {
      return console.error('No device ID');
    }

    const failed = (err: Error, broadcastError?: boolean) => {
      console.error(err);
      if (broadcastError) {
        message.error(
          `Failed to retrieve latest sensor information. Error: ${err.message}`
        );
      }
      this.setState({
        loadingSensor: false,
      });
    };

    try {
      this.setState({ loadingSensor: true });

      const eType = getEqType(e);
      const dontSend = [ETYPES.chair, ETYPES.sterilizer].includes(eType);

      if (isHandpiece(e)) {
        // DEN-664 only send these device commands for HP on details page
        sendDeviceCommand(
          e.deviceId,
          {
            arg: '{"cmd":"get_cfg", "cfg": {"config"}}',
          },
          { showErrorToast: true },
          isHandpiece(e)
        ).catch(() => {
          deviceStatus = 'off';
          this.setState({ deviceStatus });
        });

        sendDeviceCommand(
          e.deviceId,
          {
            arg: '{"cmd":"sensor_instant", "interval": 1, "duration": 1}',
          },
          { showErrorToast: true },
          isHandpiece(e)
        ).catch(() => {
          deviceStatus = 'off';
          this.setState({ deviceStatus });
        });

        sendDeviceCommand(
          e.deviceId,
          {
            arg: `{"cmd":"get_cfg", "cfg": {"config":{"dental_school"}}}`,
          },
          { showErrorToast: true },
          true
        ).catch(() => {
          //todo:?
        });
      } else {
        const isAerasOne = eType.includes('aerasone');
        if (!dontSend && !isAerasOne) {
          sendDeviceCommand(
            e.deviceId,
            {
              arg: 'get_runtimes',
            },
            { showErrorToast: true },
            isHandpiece(e)
          ).catch(err => {
            deviceStatus = 'off';
            this.setState({ deviceStatus });
            console.error('get_runtimes failed', err.message);
          });
        }

        if (isAerasOne) {
          sendDeviceCommand(e.deviceId, { cmd: 'get_config' }, { showErrorToast: true }).catch(() => 0)
        }

        if (eType !== ETYPES.sterilizer) {
          //might remove this before the demo if get_data gets setup
          sendDeviceCommand(
            e.deviceId,
            {
              arg: isAerasOne ? { cmd: 'get_data' } : 'get_data',
            },
            { showErrorToast: true },
            isHandpiece(e)
          ).catch(err => {
            deviceStatus = 'off';
            this.setState({ deviceStatus });
            console.error('get_data failed', err.message);
          });
        }
      }

      if (!dontSend) {
        await this.props.waitForNewStatistic(e);
      }

      refreshData && (await this.getDetailedData());

      if (refreshData) {
        this.trackEvent(eventTypes.eq_request_data);
      }

      this.setState({
        loadingSensor: false,
      });
    } catch (err) {
      await this.retrieveEquipment();
      failed(err);
    }
  };

  getDeviceStatus = () => {
    const e = this.getEquipment();

    return e.deviceStatus;
  };

  getDeviceStatusText = () => {
    const deviceStatus = this.getDeviceStatus();

    return deviceStatus === 'on'
      ? 'Online'
      : deviceStatus === 'standby'
        ? 'Standby'
        : 'Offline';
  };

  retrieveEquipment = async (showLoading = true) => {
    const e = this.getEquipment();
    const { sState } = this.props;

    const model = findModel(e, sState);

    this.setState({
      isLoading: true,
      loading: showLoading,
      sendingCommand: showLoading,
      model,
    });

    try {
      const equipment = await getEquipmentRecord(e.deviceId, true);
      const equipType = getEquipmentType(
        equipment.equipmentSN as string,
        equipment.modelId as string
      );
      const allEquipment = get(sState, 'dash.equipment', []) as Equipment[];
      const ids = getAllDeviceIds(equipment);
      const location = equipment.locationId
        ? await getLocation(equipment.locationId)
        : undefined;

      const tandemGroups = e.tandemGroupId
        ? await getTandemGroups({ id: { eq: e.tandemGroupId } })
        : e.locationId
          ? await getTandemGroups({ locationId: { eq: e.locationId } })
          : undefined;
      const tandemGroup =
        equipment.tandemGroupId && tandemGroups
          ? tandemGroups.find(tg => {
            return tg.id === equipment.tandemGroupId;
          })
          : undefined;
      const availableTGroups = tandemGroups
        ? tandemGroups.filter(tg => {
          const equipForTGroup = allEquipment.filter(
            equip => equip.tandemGroupId === tg.id
          );
          return (
            equipForTGroup.length &&
            equipForTGroup.length < 4 &&
            tg.equipType === equipType
          );
        })
        : [];
      const serviceOrg = equipment.serviceOrgId
        ? await getOrg(equipment.serviceOrgId)
        : undefined;

      const dentalOrg = e.dentalOrgId ? await getOrg(e.dentalOrgId) : undefined;

      const serviceHistorys = await Promise.all(
        ids.map(async id =>
          parseServiceByDate(await getService(id.id), id.replaceDate)
        )
      );

      const hasServices = (f: {}) => {
        const services = get(f, 'services', []).filter(getServiceType);
        return !!size(services);
      };

      if (isHandpiece(equipment) && equipment.deviceId) {
        sendDeviceCommand(
          equipment.deviceId,
          {
            arg: '{"cmd":"get_cfg", "cfg": {"revs": {}}}',
          },
          { showErrorToast: true },
          true
        ).catch(() => { });
      }

      const flattenedServices = flatten(serviceHistorys).filter(f =>
        hasServices(f)
      );

      this.setState({
        isLoading: false,
        loading: showLoading ? false : this.state.loading,
        sendingCommand: showLoading ? false : this.state.sendingCommand,
        equipment,
        location,
        tandemGroup,
        tandemGroups,
        serviceOrg,
        dentalOrg,
        serviceHistory: isHandpiece(e)
          ? flattenedServices
          : deDupeServices(flattenedServices),
        availableTGroups,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        loading: false,
        isLoading: false,
        loadingSensor: false,
        sendingCommand: false,
        equipment: new _Equipment(e),
      });
    }
  };
  getDetailedData = async () => {
    const eq = this.getEquipment();
    //only retrieve the alerts if we haven't done so for this piece of equipment
    this.setState({ alertsLoading: true, loadingSensor: true });
    try {
      if (eq && eq.deviceId) {
        const result = await this.loadPusherChannel(eq, { loadDetailedData: true });
        const fulleq = await getEquipmentRecord(eq.deviceId, true);

        this.fixEqAlertsIfNecessary(fulleq, get(result, '0.data', []).sort(sortPublishByTimeOrDate));
        this.fixEqMaintIfNecessary(fulleq, get(result, '0.data', []).sort(sortPublishByTimeOrDate));
        this.calculateV8ChairValues(eq, get(result, '0.data', []));

        this.setState({
          latestEquipmentPublishData: fulleq._status as EquipmentStatus,
        });
      }
    } catch (err) {
      console.error(err);
    }
    this.setState({ alertsLoading: false, loadingSensor: false });
  };
  calculateV8ChairValues = async (eq: EquipmentRecord, pubs: Publish[]) => {
    if (getEqType(eq) == 'chair') {
      const data = pubs.filter(p => get(p, 'event', '').includes('sensor_statistics')).sort(sortPublishByTimeOrDate);

      const isv8 = get(data, '0.fw_version') == 8;

      if (isv8) {
        const days = {};

        let count = 0;
        data.map(d => {
          const dayval = moment(d.published_at).dayOfYear() + '_day';
          if (!get(days, dayval)) {
            set(days, dayval, true);
            count += get(d, 'data.sessions_per_day', 0);
          }
        })

        this.setState({ totalChairCount: count });
      }

    }
  }
  fixEqAlertsIfNecessary = async (eq: EquipmentRecord, pubs: Publish[]) => {
    try {
      const curAlerts = get(eq, 'status.alerts', []);

      const alertsToFix: Publish[] = [];

      //@ts-ignore
      curAlerts.map(a => {
        const { source, time } = a;
        const match = pubs.find(ca => get(ca, 'data.source') == source && get(ca, 'data.error') !== undefined);

        //the alerts and the actual data from the history pulled do not match, so the status alerts on the eq record needs to be updated
        if (match && get(match, 'data.time') != time) {
          alertsToFix.push(match);
        }
      })

      await Promise.all(alertsToFix.map(a => createPublish(a)))
    } catch (err) { }
  }
  fixEqMaintIfNecessary = async (eq: EquipmentRecord, pubs: Publish[]) => {
    try {
      const curMaint = get(eq, 'status.maint', []);
      const maint = findLatestPublish(getEquipMaint(eq, this.props.sState, []));

      let fixMaint = false;

      //@ts-ignore
      curMaint.map(a => {
        const { key } = a;
        const match = get(maint, `data.${key}`)

        if (match && match[0] === false) {
          fixMaint = true;
        }
      })

      if (fixMaint) {
        await createPublish(maint);
      }
    } catch (err) { }
  }
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const e = this.getEquipment();
    if (e.modelId != get(prevState, 'equipment.modelId')) {
      return this.setState({ equipment: e, model: findModel(this.state.equipment, this.props.sState), maintSks: '' })
    }

    const oldmaint = get(this, 'state.maintSks', '');
    const newmaint = get(this.props.sState, `dash.equipMap.${e.deviceId}.maint`, []).map((s: any) => get(s, 'sk')).toString();
    if (oldmaint != newmaint) {
      //received a new maint from pusher, so need to check to see if we got any new service records
      this.setState({ maintSks: newmaint }, () => this.retrieveEquipment(false))
    }
  }
  componentDidMount = () => {
    this.trackEvent(eventTypes.eq_view);
    const { history, sState } = this.props;
    const state = get(history, 'location.state', {});
    this.setState({ ...state }, () => {
      this.retrieveEquipment();

      // DEN-664 do not load sensor data on page load for HP
      if (!isHandpiece(this.getEquipment())) {
        this.retrieveLatestSensor(false);
      }

      this.getDetailedData();
    });
  };
  //NEED TO HANDLE THE UNMOUNT FROM THE EXTENDED COMPONENT
  // componentWillUnmount(): void {
  //   this.unmount();      
  // }
  goBack = () => {
    const { showAllMessages, showAllHistory, showAllReminders } = this.state;
    if (showAllMessages || showAllHistory || showAllReminders) {
      return this.setState({
        showAllMessages: false,
        showAllHistory: false,
        showAllReminders: false,
      });
    }
    this.props.history.goBack();
  };
  goToAddUser = (orgId: string) => {
    this.props.history.replace({
      pathname: '/dashboard/addUser',
      state: { orgId: orgId, role: 2 },
    });
  };
  toggleChange = () => {
    if (userHasRole([6, 7], this.props.sState)) {
      this.renderBadAccessAlert();
      return;
    }
    const { sendingCommand } = this.state;

    let equipment = this.getEquipment();

    const isCurrentlyOn = equipment.deviceStatus === 'on';

    if (sendingCommand) {
      return message.error(
        'Currently sending a command. Please wait for the command to complete.'
      );
    }

    const submitStandyToggle = () => {
      this.setState(
        {
          sendingCommand: true,
        },
        async () => {
          try {
            await sendDeviceCommand(
              equipment.deviceId || '',
              {
                arg: isCurrentlyOn ? 'on,0' : 'on,1',
              },
              { showErrorToast: true },
              isHandpiece(equipment)
            );
            const latestConfig = getEquipConfig(equipment, this.props.sState);
            set(latestConfig, 'data.device_on.0', isCurrentlyOn ? false : true);
            set(latestConfig, 'data.device_on.1', moment().valueOf());
            set(latestConfig, 'fromApp', true);
            latestConfig.id = guid();
            latestConfig.published_at = new Date().toISOString();
            await createPublish(latestConfig);
            message.success(
              `Equipment has been set to ${isCurrentlyOn ? 'Standby' : 'On'}`
            );
            this.trackEvent(eventTypes.eq_toggle_status, {
              new_state: isCurrentlyOn ? 'standby' : 'on',
            });
            this.setState({ sendingCommand: false });
          } catch (err) {
            message.error('Equipment status can not be adjusted at this time.');
            this.setState({
              sendingCommand: false,
            });
          }
        }
      );
    };

    Modal.confirm({
      title: `Set Equipment to ${isCurrentlyOn ? 'Standby' : 'On'}`,
      content:
        // tslint:disable-next-line:max-line-length
        `Are you sure you want to manually set this equipment to ${isCurrentlyOn ? 'Standby' : 'On'
        }?`,
      onOk: submitStandyToggle,
      okText: isCurrentlyOn ? 'Standby' : 'On',
      onCancel: () => 0,
    });
  };
  renderSubTitle = () => {
    const { sState } = this.props;
    const { dentalOrg, loadingSensor, showAllMessages, location } = this.state;

    const styles = `margin: 0;`;

    const showBtn = !dentalOrg && !location;
    const hasLocation = get(location, 'name');

    const labels = [
      renderLabel('name', {
        obj: dentalOrg,
        styles: styles + 'font-weight: bold;',
      }),
      showBtn ? (
        <Button
          title="Assign Customer"
          onClick={() => this.props.history.push('/dashboard/manageDentalOrgs')}
        />
      ) : (
        renderLabel('name', {
          obj: { name: hasLocation || 'No Location' },
          styles: styles + (!hasLocation && 'font-style: italic'),
        })
      ),
    ].filter(l => l);

    const address = renderLabel('address', {
      obj: dentalOrg,
      styles: styles,
    });

    const ispb = isPB(sState);

    return (
      <div
        css={css(
          ispb ? SharedStyles.column : SharedStyles.row,
          `align-items: start`
        )}
      >
        <div css={css(SharedStyles.column, `align-items: start`)}>
          <div
            css={css(SharedStyles.row, `font-size: 12px; margin-bottom: 10px;`)}
          >
            {labels.map((l, i) => {
              return (
                <div css={css(SharedStyles.row)} key={i}>
                  {l}
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {i === labels.length - 1 ? '' : !ispb ? '•' : ''}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div>
              );
            })}
          </div>
          <div css={css(`margin-bottom: 10px; font-size: 12px;`)}>
            {!!get(dentalOrg, 'address.address', '') && <div>{address}</div>}
          </div>
        </div>

        {!showAllMessages && (
          <Button
            css={css(ispb ? `margin-bottom: 15px` : `margin-left: auto;`)}
            title="Get Latest Sensor Data"
            loading={loadingSensor}
            onClick={() => this.retrieveLatestSensor(true)}
          />
        )}
      </div>
    );
  };
  getSensorInfo = (): ISensorInfo => {
    const { sState } = this.props;
    const { latestEquipmentPublishData, equipment } = this.state;

    if (!latestEquipmentPublishData) {
      return {};
    }

    const sensors = get(
      sState,
      `dash.equipMap.${equipment.deviceId}.sensorStatistics`,
      []
    ).sort(sortPublishByTimeOrDate);

    // tslint:disable-next-line:no-any
    const daily = sensors.filter((s: Publish) => get(s, 'data.daily'));
    const instants = sensors.filter(
      (s: { event: string }) => s.event === 'dz_sensor_instant'
    );
    const latestStats = sensors
      // tslint:disable-next-line:no-any
      .filter((s: Publish) => s.event == 'dz_sensor_statistics')
      .sort(sortPublishByTimeOrDate);
    const latestInstantPublish = findLatestPublish(instants);
    let latestStatisticsPublish = findLatestPublish(latestStats);

    const latestMaintPublish = findLatestPublish(
      getEquipMaint(equipment, sState)
    );

    var latestPublish;

    if (
      _size(latestInstantPublish) &&
      _size(latestStatisticsPublish) &&
      latestInstantPublish.published_at &&
      latestStatisticsPublish.published_at
    ) {
      latestPublish =
        latestInstantPublish.published_at > latestStatisticsPublish.published_at
          ? latestInstantPublish
          : latestStatisticsPublish;
    } else if (_size(latestInstantPublish)) {
      latestPublish = latestInstantPublish;
    } else if (_size(latestStatisticsPublish)) {
      latestPublish = latestStatisticsPublish;
    }

    if (!latestPublish) {
      return {};
    }

    const isLatestPublishInstant =
      get(latestPublish, 'event', '').indexOf('instant') > -1;

    const isHp = isHandpiece(equipment);
    const eqType = getEqType(equipment);

    // latestPublish data is either gonna be right there (if it is an instant of sensor_instant)
    // OR the data is going to be the 3rd item (2nd index) in an array (if it is an instant of sensor_statistics)
    const rawAccessor = isLatestPublishInstant ? '' : '.2';

    let cyclesTotal = 0;
    let cyclesSuccess = 0;
    if (eqType === ETYPES.sterilizer) {
      const relevant = latestStats.filter(
        //@ts-ignore
        f =>
          !get(f, 'data.daily') &&
          moment(f.published_at).isSame(moment(), 'day')
      );
      //@ts-ignore
      relevant.map(r => {
        const suc = get(r, 'data.success', false);
        cyclesTotal++;
        if (suc) {
          cyclesSuccess++;
        }
      });

      latestStatisticsPublish = latestStats.find(
        //@ts-ignore
        // tslint:disable-next-line:no-any
        (s: Publish) => !get(s, 'data.daily')
      );
    }

    const specialAdds = {};

    if (eqType === ETYPES.chair) {
      const currentDay = [];
      set(specialAdds, 'totalChairCount', this.state.totalChairCount);
      for (let i = 0; i < latestStats.length; i++) {
        const s = latestStats[i];
        const isSame = moment().isSame(moment(s.published_at), 'day');
        if (isSame) {
          currentDay.push(s);
        } else {
          break;
        }
      }
      set(specialAdds, 'currentDay', uniqBy(currentDay, 'sk'));
    }

    const sn = {
      ...specialAdds,
      ...get(latestPublish, 'data', {}),
      latestStats,
      latest: latestPublish,
      latestStat: latestStatisticsPublish,
      latestDaily: first(daily),
      latestStat2: latestStats.find(
        // tslint:disable-next-line:no-any
        (l: Publish) => get(l, 'data.type') == 2
      ),
      latestMaint: get(latestEquipmentPublishData, 'dz_maint'),
      latestInstant: latestInstantPublish,
      cyclesTotal,
      cyclesSuccess,
      BP: get(latestPublish, `data.BP${rawAccessor}`, null),
      PS: get(latestPublish, `data.PS${rawAccessor}`, null),
      M1T: get(latestPublish, `data.M1T${rawAccessor}`, null),
      M2T: get(latestPublish, `data.M2T${rawAccessor}`, null),
      M3T: get(latestPublish, `data.M3T${rawAccessor}`, null),
      M1P: get(latestPublish, `data.M1P${rawAccessor}`, null),
      M2P: get(latestPublish, `data.M2P${rawAccessor}`, null),
      M3P: get(latestPublish, `data.M3P${rawAccessor}`, null),
      TP: get(latestPublish, `data.TP${rawAccessor}`, null),
      TT: get(latestPublish, `data.TT${rawAccessor}`, null),
      TH: get(latestPublish, `data.TH${rawAccessor}`, null),
      AT: get(latestPublish, `data.AT${rawAccessor}`, null),
      AH: get(latestPublish, `data.AH${rawAccessor}`, null),
      VL: get(latestPublish, `data.VL${rawAccessor}`, null),
      M1R: get(latestMaintPublish, `data.motor1.1`, null),
      M2R: get(latestMaintPublish, `data.motor2.1`, null),
      M3R: get(latestMaintPublish, `data.motor3.1`, null),
      ER: get(latestMaintPublish, `data.general.1`, null),
      PDP: get(latestPublish, `data.PDP${rawAccessor}`, null),
      ALT: get(latestPublish, `data.ALT${rawAccessor}`, null),
      DE: get(latestPublish, `data.DE${rawAccessor}`, null),
      RGT: get(latestPublish, `data.RGT${rawAccessor}`, null),
      PU: get(daily, `0.data.kwh`, null),
      HDC: get(latestPublish, `data.HDC${rawAccessor}`, null),
      ODR: get(latestPublish, `data.ODR${rawAccessor}`, null),
      VE: get(latestPublish, `data.VE${rawAccessor}`, null),
      PR: get(latestMaintPublish, `data.pump.1${rawAccessor}`, null),
      WO: get(latestPublish, `data.WO${rawAccessor}`, null),
      OL: get(latestPublish, `data.OL${rawAccessor}`, null),
      WP: get(latestPublish, `data.WP${rawAccessor}`, null),
      IP: get(latestPublish, `data.IP${rawAccessor}`, null),
      PT: get(latestPublish, `data.PT${rawAccessor}`, null),
      TMP: get(
        latestPublish,
        isHp ? `data.TMP${rawAccessor}` : `data.0${rawAccessor}`,
        null
      ),
      AP: get(
        latestPublish,
        isHp ? `data.AP${rawAccessor}` : `data.2${rawAccessor}`,
        null
      ),
      TOQ: get(latestPublish, `data.3${rawAccessor}`, null),
      TOQAVG: get(latestStatisticsPublish, `data.TOQ.2`, null), //todo check
      TOQMAX: get(latestStatisticsPublish, `data.TOQ.1`, null), //todo check
      SPD: get(latestPublish, `data.4${rawAccessor}`, null),
      SPDAVG: get(latestStatisticsPublish, `data.SPD.2`, null), //todo check
      SPDMAX: get(latestStatisticsPublish, `data.SPD.1`, null), //todo check
      IA: get(
        latestPublish,
        isHp ? `data.IA${rawAccessor}` : `data.5${rawAccessor}`,
        null
      ),
      IB: get(
        latestPublish,
        isHp ? `data.IB${rawAccessor}` : `data.6${rawAccessor}`,
        null
      ),
      IC: get(
        latestPublish,
        isHp ? `data.IC${rawAccessor}` : `data.7${rawAccessor}`,
        null
      ),
      TOTI: get(
        latestPublish,
        isHp ? `data.TOTI${rawAccessor}` : `data.8${rawAccessor}`,
        null
      ),
      EMFA: get(
        latestPublish,
        isHp ? `data.EMFA${rawAccessor}` : `data.10${rawAccessor}`,
        null
      ),
      EMFB: get(
        latestPublish,
        isHp ? `data.EMFB${rawAccessor}` : `data.11${rawAccessor}`,
        null
      ),
      EMFC: get(
        latestPublish,
        isHp ? `data.EMFC${rawAccessor}` : `data.12${rawAccessor}`,
        null
      ),
      DC: get(latestPublish, `data.13${rawAccessor}`, null),
      PWR: get(latestPublish, `data.14${rawAccessor}`, null),
      VDC: get(
        latestPublish,
        isHp ? `data.VDC${rawAccessor}` : `data.14${rawAccessor}`,
        null
      ),
      AMG: get(latestInstantPublish, `data.AMG`, null),
      bars: get(latestPublish, `data.bars${rawAccessor}`, null),
      MTR: isHp
        ? get(latestPublish, `data.MTR${isLatestPublishInstant ? '' : '.0'}`, 0)
        : 0, // DEN-848 this value is a single value in when it is coming from sensor_stat publishes where we normall expect a 3 member array and pull the last one.
      when: get(latestPublish, 'published_at'),
    };

    // console.log({ sn });

    return sn;
  };
  clickCol = (sensor: string, sensorInfo: ISensorInfo, opts = {}) => {
    const { loadingSensor } = this.state;
    if (loadingSensor) return;
    if ((sensorInfo[sensor] == null || get(opts, 'data') === null) && sensor !== 'AMG' && !get(opts, 'data')) {
      return Modal.warn({
        title: 'Data Not Available',
        content:
          //tslint:disable-next-line: max-line-length
          'Data is not currently available for this sensor.  If you feel that this is an error, call DENTALEZ tech support (866-383-4636).',
        onOk: () => 0,
      });
    }
    this.trackEvent(eventTypes.eq_sensor_history, { sensorId: sensor });
    this.setState({
      modalChart: {
        ...opts,
        visible: true,
        chosenSensor: sensor,
      },
    });
  };
  renderInfoRow1 = (row?: { title: string; columns: ICol[] }) => {
    if (!row) {
      return null;
    }

    const { sState } = this.props;
    const { loadingSensor } = this.state;
    const ispb = isPB(sState);

    return (
      <InfoRow
        isPhoneBreak={ispb}
        header={() => (
          <div css={css(SharedStyles.row, `font-weight: bold; padding: 5px;`)}>
            <div css={css('position: relative;')}>
              {row.title}
              {loadingSensor && (
                <Icon
                  type="loading"
                  css={css('position: absolute; right: -20px; bottom: 2px;')}
                />
              )}
            </div>
          </div>
        )}
        columns={row.columns}
      />
    );
  };
  renderInfoRowEx = (row?: { title: string; columns: ICol[] }, opts = {}) => {
    const { sState } = this.props;
    const { sensorsExpanded } = this.state;

    if (!row) {
      return null;
    }

    const ispb = isPB(sState);

    opts = {
      includeLink: false,
      sensorInfo: null,
      equipment: null,
      ...opts,
    };

    const sensorInfo = get(opts, 'sensorInfo');
    const e = get(opts, 'equipment');

    return (
      <div>
        <div
          css={css(SharedStyles.row, `margin-left: -4px; margin-bottom: 18px;`)}
        >
          {sensorsExpanded && <div css={css(styles.title)}>{row.title}</div>}

          {!!get(opts, 'includeLink') && (
            <div
              css={css(
                `margin-left: auto; margin-top: 15px; margin-bottom: ${sensorsExpanded ? 15 : 0
                }px;`
              )}
            >
              {sensorsExpanded && (
                <div css={css(styles.date)}>
                  {!!get(sensorInfo, 'when')
                    ? `${isHandpiece(e)
                      ? 'Data from last session on'
                      : 'Last Updated'
                    }: ${moment(sensorInfo.when).format('MMM DD h:mm a')}`
                    : ''}
                </div>
              )}

              <Link
                css={css`
                  margin-left: auto;
                `}
                onClick={() =>
                  this.setState({ sensorsExpanded: !sensorsExpanded }, () => {
                    this.state.sensorsExpanded &&
                      this.retrieveLatestSensor(true);
                  })
                }
              >
                {`See ${sensorsExpanded ? 'Less' : 'More'} Sensor Details`}
              </Link>
            </div>
          )}
        </div>

        {sensorsExpanded && (
          <InfoRow noFill={true} isPhoneBreak={ispb} columns={row.columns} />
        )}
      </div>
    );
  };
  renderBlank = (msg: string) => {
    return <p css={css(styles.blankMessage)}>{msg}</p>;
  };
  // tslint:disable-next-line:no-any
  openModal = (row: any) => {
    const alert = row;
    this.setState({
      modalVisible: true,
      selectedPublish: alert,
    });
  };
  renderPerProfileUsage = (): JSX.Element => {
    const { isLoading, latestEquipmentPublishData } = this.state;

    const profiledata = get(
      latestEquipmentPublishData,
      'dz_profile_runtimes.data.profiles'
    );

    return (
      <div>
        <div css={css(SharedStyles.row, styles.title, 'margin-top: 10px;')}>
          {'Profile Usage'}
        </div>
        {isLoading ? (
          <Icon type="loading" />
        ) : latestEquipmentPublishData && !!profiledata ? (
          <HandpieceProfileUsageTable data={profiledata} />
        ) : (
          this.renderBlank(
            'No profile data has been received for this equipment.'
          )
        )}
        <div css={css(styles.hrMain)} />
      </div>
    );
  };
  activeAlerts: Publish[] = [];
  renderMessages = () => {
    const { sState } = this.props;
    const {
      showAllMessages,
      loading,
      alertsLoading,
      expandAlerts,
    } = this.state;
    const e = this.getEquipment();

    const ispb = isPB(sState);
    const eqType = getEqType(e);

    if (eqType === ETYPES.sterilizer) {
      return this.renderHistory();
    }

    const startDate = moment(e.installDate);
    const alerts = get(sState, `dash.equipMap.${e.deviceId}.alerts`, []).filter(
      // tslint:disable-next-line:no-any
      (a: Publish) => moment(a.published_at).isAfter(startDate)
    );
    const alertMap = { ...get(e, `_status.alertMap`, {}) as any, ...get(sState, `dash.equipMap.${e.deviceId}.alertMap`, {}) };

    let activeAlerts = [];
    for (let k in alertMap) {
      let opts = alertMap[k];
      if (get(opts, 'data.error', 0) > 0 && moment(opts.published_at).isAfter(startDate)) {
        activeAlerts.push(opts);
      }
    }
    activeAlerts = orderBy(activeAlerts, 'published_at', 'desc');
    this.activeAlerts = activeAlerts;

    const isUserOrgReadOnly = userHasRole(6, sState);
    const isUserDezReadOnly = userHasRole(7, sState);
    const isUserReadOnly = isUserOrgReadOnly || isUserDezReadOnly;

    const getSensor = (source: string) => {
      let sensor = get(sState, 'dash.sensors', []).find(
        (s: Sensor) => s.id === source
      );

      if (!sensor) {
        sensor = { id: source };
      }

      return sensor;
    };

    const columns: ColumnProps<any>[] = [
      {
        dataIndex: 'time',
        title: 'Date',
        width: ispb ? undefined : '120px',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          const time = moment(getTimeForPubAlert(msg)).format(
            defaultDateFormat
          );
          return <div css={css(`width: 200px;`)}>{time}</div>;
        },
      },
      {
        dataIndex: 'alert',
        title: 'Alert',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          const equipment = this.state.equipment;
          const rawAccessor = isHandpiece(equipment) ? 'data.alert' : 'data';
          const source = get(msg, `${rawAccessor}.source`);
          const sensor = getSensor(source);
          const errorCode = get(msg, `${rawAccessor}.error`);
          const equipType = getEqType(equipment);
          return sensor ? (
            <span>{getAlert(sensor.id, errorCode, equipType)}</span>
          ) : null;
        },
      },
      ... !ispb ? [{
        dataIndex: 'message',
        title: 'Message',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          const equipment = this.state.equipment;
          const rawAccessor = isHandpiece(equipment) ? 'data.alert' : 'data';
          const eDef = errorDef(get(msg, `${rawAccessor}.error`, 0));
          const source = get(msg, `${rawAccessor}.source`);
          const sensor = getSensor(source);
          const errorCode = get(msg, `${rawAccessor}.error`);
          const equipType = getEqType(equipment);
          return (
            <div css={css(SharedStyles.row)}>
              <Icon
                type={'warning'}
                theme={'filled'}
                css={css(`font-size: 16px; color: ${eDef.color}`)}
              />
              <div css={css(SharedStyles.ellipsisText, `margin-left: 10px;`)}>
                {sensor ? getMessage(sensor.id, errorCode, equipType) : null}
              </div>
            </div>
          );
        },
      }] : [],
      ... (!ispb &&
        !isUserReadOnly) ? [{
          dataIndex: 'clear',
          title: 'Clear',
          // tslint:disable-next-line:no-any
          render: (text: string, msg: Publish) => {
            const rawAccessor = isHandpiece(this.state.equipment)
              ? 'data.alert'
              : 'data';
            const eDef = errorDef(get(msg, `${rawAccessor}.error`, 0));
            const source = get(msg, `${rawAccessor}.source`);
            const alertInMap = get(alertMap, source);
            return eDef.isMinor && alertInMap ? (
              <Icon
                type={'close-circle'}
                theme={'filled'}
                onClick={() => this.openModal(msg)}
                css={css(`font-size: 16px; color: ${colors.highlight}`)}
              />
            ) : null;
          },
        }] : [],
    ].filter(f => f);

    const showingActive = !showAllMessages;

    const toggleActive = () => {
      this.setState({ showAllMessages: !showAllMessages });
      if (!showAllMessages) {
        this.trackEvent(eventTypes.eq_alert_history);
      }
    };

    const ignore = get(
      getEquipmentModel(e, sState),
      'ignoreAlerts',
      []
    ) as string[];
    const data = (showAllMessages ? alerts : activeAlerts).filter(
      // tslint:disable-next-line:no-any
      (d: Publish) => {
        const asensor = get(d, 'data.source');
        return ignore.indexOf(asensor) === -1;
      }
    );
    const showBlank =
      ((!loading && !showAllMessages) || (showAllMessages && !alertsLoading)) &&
      data &&
      data.length === 0;
    return ispb ? (
      <div>
        <div
          onClick={() =>
            this.setState({
              expandAlerts: !expandAlerts,
              showAllMessages: expandAlerts ? false : showAllMessages,
            })
          }
          css={css(SharedStyles.row, styles.expandRow)}
        >
          <div>Alerts</div>
          <Icon
            css={css('margin-left: auto;')}
            type={expandAlerts ? 'down' : 'right'}
          />
        </div>

        {expandAlerts && (
          <div css={css(styles.overflowHidden)}>
            <div css={css(SharedStyles.row, styles.title, 'margin-top: 10px;')}>
              {showingActive ? 'Active Alerts' : `Message History`}
              {!showingActive && (
                <Link
                  css={css(styles.showMore, 'margin-left: auto;')}
                  onClick={toggleActive}
                >
                  {`Show ${showAllMessages ? 'Active Alerts' : 'Alert History'
                    }`}
                </Link>
              )}
            </div>

            {!showBlank && (
              <TableList
                loading={showAllMessages ? alertsLoading : loading}
                columns={columns}
                data={data as never[]}
                rowClick={this.openModal}
                showPagination={showAllMessages}
                pageSize={50}
                canSelectRows={false}
                implementScroll={false}
              />
            )}

            {showBlank &&
              this.renderBlank(
                showingActive
                  ? 'No active alerts exist.'
                  : 'No messages have been received for this equipment.'
              )}

            <Link
              css={css(styles.showMore)}
              onClick={() =>
                this.setState({ showAllMessages: !showAllMessages })
              }
            >
              {`Show ${showAllMessages ? 'Active Alerts' : 'Alert History'}`}
            </Link>
          </div>
        )}

        <div css={css(styles.hrMain)} />
      </div>
    ) : (
      <div>
        <div css={css(styles.overflowHidden)}>
          <div css={css(SharedStyles.row, styles.title)}>
            {showingActive ? 'Active Alerts' : `Message History`}
            {!showingActive && (
              <Link
                css={css(styles.showMore, 'margin-left: auto;')}
                onClick={toggleActive}
              >
                {`Show ${showAllMessages ? 'Active Alerts' : 'Alert History'}`}
              </Link>
            )}
          </div>

          {!showBlank && (
            <TableList
              loading={showAllMessages ? alertsLoading : loading}
              columns={columns}
              data={data as never[]}
              rowClick={this.openModal}
              showPagination={showAllMessages}
              pageSize={50}
              canSelectRows={false}
              implementScroll={false}
            />
          )}

          {showBlank &&
            this.renderBlank(
              showingActive
                ? 'No active alerts exist.'
                : 'No messages have been received for this equipment.'
            )}

          <Link css={css(styles.showMore)} onClick={toggleActive}>
            {`Show ${showAllMessages ? 'Active Alerts' : 'Alert History'}`}
          </Link>
        </div>

        {!showAllMessages && <div css={css(styles.hrMain)} />}
      </div>
    );
  };
  renderHistory = () => {
    const { sState } = this.props;
    const {
      showAllMessages,
      loading,
      alertsLoading,
      expandAlerts,
    } = this.state;
    const e = this.getEquipment();

    const ispb = isPB(sState);

    const alerts = get(
      sState,
      `dash.equipMap.${e.deviceId}.sensorStatistics`,
      []
    ).filter(
      //@ts-ignore
      f => !get(f, 'data.daily') && get(f, 'event') !== 'dz_sensor_instant'
    );
    const alertMap = get(sState, `dash.equipMap.${e.deviceId}.alertMap`, {});

    const activeAlerts = [];
    for (let k in alertMap) {
      let opts = alertMap[k];
      activeAlerts.push(opts);
    }

    const columns = [
      {
        dataIndex: 'data.start',
        title: 'Start',
        // width: ispb ? undefined : '120px',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          const time = moment(getRightTime(_text)).format(defaultDateFormat);
          return time;
        },
      },
      {
        dataIndex: 'data.end',
        title: 'Completion',
        // width: ispb ? undefined : '120px',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          const time = moment(getRightTime(_text)).format(defaultDateFormat);
          return time;
        },
      },
      {
        dataIndex: 'data.success',
        title: 'Status',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          const isSuccess = !!_text;
          return (
            <span style={{ color: isSuccess ? colors.success : colors.error }}>
              {isSuccess ? 'Pass' : 'Fail'}
            </span>
          );
        },
      },
      {
        dataIndex: 'data.flags',
        title: 'Errors',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          return getSterilizerFlagTextFromArray(get(
            msg,
            'data.flags',
            []
          ) as string[]);
        },
      },
      {
        dataIndex: 'data.time_target',
        title: 'Target Time',
        // width: ispb ? undefined : '120px',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          const time = timeText(_text, { includeSeconds: true });
          return time;
        },
      },
      {
        dataIndex: 'data.time_elapsed',
        title: 'Elapsed Time',
        // width: ispb ? undefined : '120px',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          const time = timeText(_text, { includeSeconds: true });
          return time;
        },
      },
      {
        dataIndex: 'data.temp_target',
        title: 'Target Temp',
        // width: ispb ? undefined : '120px',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          return `${_text} F`;
        },
      },
      {
        dataIndex: 'data.temp_reached',
        title: 'Achieved Temp',
        // width: ispb ? undefined : '120px',
        // tslint:disable-next-line:no-any
        render: (_text: string, msg: Publish) => {
          return `${_text} F`;
        },
      },
    ].filter(f => f);

    const showingActive = !showAllMessages;

    const toggleActive = () => {
      this.setState({ showAllMessages: !showAllMessages });
      if (!showAllMessages) {
        this.trackEvent(eventTypes.eq_alert_history);
      }
    };

    const titles = {
      active: 'Sterilization History',
      all: 'Equipment Details',
    };

    const data = showAllMessages ? alerts : take(alerts, 10);
    const showBlank =
      ((!loading && !showAllMessages) || (showAllMessages && !alertsLoading)) &&
      data &&
      data.length === 0;

    return ispb ? (
      <div>
        <div
          onClick={() =>
            this.setState({
              expandAlerts: !expandAlerts,
              showAllMessages: expandAlerts ? false : showAllMessages,
            })
          }
          css={css(SharedStyles.row, styles.expandRow)}
        >
          <div>Sterilization History</div>
          <Icon
            css={css('margin-left: auto;')}
            type={expandAlerts ? 'down' : 'right'}
          />
        </div>

        {expandAlerts && (
          <div css={css(styles.overflowHidden)}>
            <div css={css(SharedStyles.row, styles.title, 'margin-top: 10px;')}>
              {showingActive ? titles.active : titles.all}
              {!showingActive && (
                <Link
                  css={css(styles.showMore, 'margin-left: auto;')}
                  onClick={toggleActive}
                >
                  {`Show ${showAllMessages ? titles.active : titles.all}`}
                </Link>
              )}
            </div>

            {!showBlank && (
              <TableList
                loading={showAllMessages ? alertsLoading : loading}
                columns={columns}
                data={data as never[]}
                showPagination={showAllMessages}
                pageSize={50}
                canSelectRows={false}
                implementScroll={false}
              />
            )}

            {showBlank &&
              this.renderBlank(
                showingActive
                  ? 'No sterilization history exists.'
                  : 'No publishes have been received for this equipment.'
              )}

            <Link
              css={css(styles.showMore)}
              onClick={() =>
                this.setState({ showAllMessages: !showAllMessages })
              }
            >
              {`Show ${showAllMessages ? titles.all : titles.active}`}
            </Link>
          </div>
        )}

        <div css={css(styles.hrMain)} />
      </div>
    ) : (
      <div>
        <div css={css(styles.overflowHidden)}>
          <div css={css(SharedStyles.row, styles.title)}>
            {titles.active}
            {!showingActive && (
              <Link
                css={css(styles.showMore, 'margin-left: auto;')}
                onClick={toggleActive}
              >
                {`Show ${showAllMessages ? titles.all : titles.active}`}
              </Link>
            )}
          </div>

          {!showBlank && (
            <TableList
              loading={showAllMessages ? alertsLoading : loading}
              columns={columns}
              data={data as never[]}
              showPagination={showAllMessages}
              pageSize={50}
              canSelectRows={false}
              implementScroll={false}
            />
          )}

          {showBlank &&
            this.renderBlank(
              showingActive
                ? `No history exists.`
                : 'No messages have been received for this equipment.'
            )}

          <Link css={css(styles.showMore)} onClick={toggleActive}>
            {`Show ${showAllMessages ? titles.all : titles.active}`}
          </Link>
        </div>
      </div>
    );
  };
  renderServiceReminders = () => {
    const { sState } = this.props;
    const { loading, expandMaint } = this.state;
    const e = this.getEquipment();

    const ispb = isPB(sState);

    const maint = getEquipMaint(e, sState, []);
    const latestMaint = findLatestPublish(maint);

    const eqType = getEqType(e);
    if (eqType === ETYPES.sterilizer) {
      return null;
    }

    let serviceReminders: IServiceReminder[] = getTriggeredMaint(
      latestMaint
    ).map(lm => {
      const lastServiced = moment(
        convertDZNowToJSNow(get(lm, 'timeOfReset'))
      ).format(defaultDateFormat);
      const hours = {
        text:
          lm.timeRemaining === 0
            ? '0 hours remaining'
            : lm.timeRemaining < 0
              ? `Service Overdue`
              : `${lm.timeRemaining} hours remaining`,
        color: lm.timeRemaining <= 0 ? colors.error : '',
      };
      return {
        name: lm.text,
        hours,
        lastServiced,
      };
    });

    if (isHandpiece(e)) {
      const [dueForService, hours, lastServiced] = handpieceIsDueForService(
        sState,
        e,
        this.state.serviceHistory
      );
      if (dueForService && hours > 0) {
        let text = '';

        if (hours >= 450 && hours < 500) {
          const remaining = 500 - hours;
          text = `${remaining === 1
            ? '1 hour remaining'
            : `${remaining} hours remaining`
            }`;
        } else if (hours === 500) {
          text = 'Due now';
        } else if (hours > 500) {
          text = `Service Overdue`;
        }

        serviceReminders.push({
          name: 'General Maintenance',
          hours: {
            text,
            color: hours <= 500 ? '' : colors.error,
          },
          lastServiced: lastServiced
            ? lastServiced.format('MMM Do YYYY h:mm a')
            : '',
        });
      }
    }

    serviceReminders.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    const columns: ColumnProps<IServiceReminder>[] = [
      {
        dataIndex: 'name',
        title: 'Component',
        width: '30%',
      },
      {
        dataIndex: 'hours',
        title: 'Service Due',
        width: '30%',
        render: (text: string, record: IServiceReminder) => {
          return (
            <div
              css={record.hours.color && css(`color: ${record.hours.color};`)}
            >
              {record.hours.text}
            </div>
          );
        },
      },
      {
        dataIndex: 'lastServiced',
        title: 'Last Serviced',
        width: '30%',
      },
      ... !ispb ? [{
        dataIndex: 'action',
        title: ' ',
        width: '10%',
        render: (text: string, record: IServiceReminder) => {
          return (
            <div css={css(SharedStyles.row)}>
              <Link onClick={() => this.goToServiceRecord(undefined)}>
                <div css={css(SharedStyles.row)}>
                  <_Icon name="plus" size={14} color={colors.highlight} />
                  <div
                    css={css`
                      margin-left: 5px;
                    `}
                  >
                    Add Service Record
                  </div>
                </div>
              </Link>
            </div>
          );
        },
      }] : [],
    ];

    const showBlank =
      !loading && serviceReminders && serviceReminders.length === 0;

    if (isChair(e)) {
      return null;
    }

    return ispb ? (
      <div>
        <div
          onClick={() =>
            this.setState({
              expandMaint: !expandMaint,
            })
          }
          css={css(SharedStyles.row, styles.expandRow)}
        >
          <div>Maintenance Alert</div>
          <Icon
            css={css('margin-left: auto;')}
            type={expandMaint ? 'down' : 'right'}
          />
        </div>
        {expandMaint && (
          <div>
            <div css={css(styles.overflowHidden)}>
              {!showBlank && (
                <TableList
                  loading={loading}
                  columns={columns}
                  data={serviceReminders as never[]}
                  showPagination={false}
                  canSelectRows={false}
                  implementScroll={false}
                />
              )}

              {showBlank &&
                this.renderBlank(
                  'No maintenance alerts have been received for this equipment.'
                )}
            </div>
          </div>
        )}
        <div css={css(styles.hrMain)} />
      </div>
    ) : (
      <div>
        <div css={css(styles.overflowHidden)}>
          <div css={css(SharedStyles.row)}>
            <div css={css(styles.title)}>Maintenance Alert</div>
          </div>

          {!showBlank && (
            <TableList
              loading={loading}
              columns={columns}
              data={serviceReminders as never[]}
              showPagination={false}
              canSelectRows={false}
              implementScroll={false}
            />
          )}

          {showBlank &&
            this.renderBlank(
              'No maintenance alerts have been received for this equipment.'
            )}
        </div>
        <div css={css(styles.hrMain)} />
      </div>
    );
  };
  renderServiceRecords = () => {
    const {
      serviceHistory,
      showAllHistory,
      loading,
      expandService,
    } = this.state;
    const { sState } = this.props;
    const e = this.getEquipment();

    const ispb = isPB(sState);

    const eqType = getEqType(e);
    if (eqType === ETYPES.sterilizer) {
      return null;
    }

    const limit = 10;

    const width = get(
      document.getElementById('ServiceRecordContainer'),
      'offsetWidth',
      1000
    );

    const columns: ColumnProps<Service>[] = [
      {
        dataIndex: 'serviceDate',
        title: 'Date',
        width: '180px',
        render: (text: string) => {
          return <div>{moment(text || undefined).format('MMM Do YYYY')}</div>;
        },
      },
      ... !ispb ? [{
        dataIndex: 'techName',
        title: 'Serviced By',
        width: '180px',
        render: (text: string) => {
          return <div>{text || 'N/A'}</div>;
        },
      }] : [],
      ... !ispb ? [{
        dataIndex: 'taskSummary',
        title: 'Components',
        render: (text: string, record: Service) => {
          const services = (get(record, 'services', []) as ServiceSerial[])
            .filter(getServiceType)
            .map(s => get(getServiceType(s), 'name'));

          const _css = `
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: ${width - 460}px;
          `;
          return (
            <div css={css(SharedStyles.row, _css)}>
              {services.length > 0 ? services.join(', ') : 'None'}
            </div>
          );
        },
      }] : [],
      {
        dataIndex: 'services',
        title: 'Services',
        width: '60px',
        render: (text: string, record: Service) => {
          const services = (get(record, 'services', []) as ServiceSerial[])
            .filter(getServiceType)
          return <div>{`${(size(services)) || '-'}`}</div>;
        },
      },
    ];

    const showBlank = !loading && serviceHistory && serviceHistory.length === 0;

    const data = (showAllHistory
      ? serviceHistory
      : take(serviceHistory, limit)) as never[];

    return ispb ? (
      <div>
        <div
          onClick={() =>
            this.setState({
              expandService: !expandService,
              showAllHistory: expandService ? false : showAllHistory,
            })
          }
          css={css(SharedStyles.row, styles.expandRow)}
        >
          <div>Service Records</div>
          <Icon
            css={css('margin-left: auto;')}
            type={expandService ? 'down' : 'right'}
          />
        </div>

        {expandService && (
          <div id={'ServiceRecordContainer'}>
            <div css={css(styles.overflowHidden)}>
              <div css={css(SharedStyles.row)}>
                <Link
                  onClick={() => this.goToServiceRecord(undefined)}
                  css={css(SharedStyles.row, styles.rightLink)}
                >
                  <_Icon name="plus" css={css(`margin-right: 5px`)} />
                  <span>Add Service Record</span>
                </Link>
              </div>

              {!showBlank && (
                <TableList
                  loading={loading}
                  columns={columns}
                  data={
                    (showAllHistory
                      ? serviceHistory
                      : take(serviceHistory, limit)) as never[]
                  }
                  showPagination={false}
                  canSelectRows={false}
                  implementScroll={false}
                  rowClick={row => this.goToServiceRecord(row, false)}
                />
              )}

              {showBlank &&
                this.renderBlank(
                  'No service records have been entered for this equipment.'
                )}

              {serviceHistory && serviceHistory.length > limit && (
                <Link
                  css={css(styles.showMore)}
                  onClick={() =>
                    this.setState({ showAllHistory: !showAllHistory })
                  }
                >
                  {`Show ${showAllHistory ? 'Less' : 'More'}`}
                </Link>
              )}
            </div>
          </div>
        )}
        <div css={css(styles.hrMain)} />
      </div>
    ) : (
      <div id={'ServiceRecordContainer'}>
        <div css={css(styles.overflowHidden)}>
          <div css={css(SharedStyles.row)}>
            <div css={css(styles.title)}>Service Records</div>
            <Link
              onClick={() => this.goToServiceRecord(undefined)}
              css={css(SharedStyles.row, styles.rightLink)}
            >
              <_Icon name="plus" css={css(`margin-right: 5px`)} />
              <span>Add Service Record</span>
            </Link>
          </div>

          {!showBlank && (
            <TableList
              loading={loading}
              columns={columns}
              data={data}
              showPagination={false}
              canSelectRows={false}
              implementScroll={false}
              rowClick={row => this.goToServiceRecord(row, false)}
            />
          )}

          {showBlank &&
            this.renderBlank(
              'No service records have been entered for this equipment.'
            )}

          {serviceHistory && serviceHistory.length > limit && (
            <Link
              css={css(styles.showMore)}
              onClick={() => this.setState({ showAllHistory: !showAllHistory })}
            >
              {`Show ${showAllHistory ? 'Less' : 'More'}`}
            </Link>
          )}
        </div>
      </div>
    );
  };
  onEditClick = () => {
    const { equipment } = this.state;
    this.props.history.push('/dashboard/editEquipment', {
      equipment,
    });
  };
  goToServiceRecord = (serviceRecord: Service | undefined, editMode = true) => {
    const { model, equipment } = this.state;
    const [due] = handpieceIsDueForService(
      this.props.sState,
      equipment,
      this.state.serviceHistory
    );

    this.props.history.push('/dashboard/manageServiceRecord', {
      equipment: this.getEquipment(),
      model,
      serviceRecord,
      editMode,
      serviceTypes: due ? [serviceType.hp_general] : [],
    });
  };
  goToBillingHistory = (_equipmentId: string) => {
    let { model, latestEquipmentPublishData, equipment } = this.state;
    let config = latestEquipmentPublishData
      ? latestEquipmentPublishData.dz_config
      : undefined;
    const appState = this.props.sState;

    if (!equipment) {
      equipment = this.getEquipment();
    }

    if (!config) {
      config = getEquipConfig(equipment, appState);
    }

    this.props.history.push('/dashboard/billingHistory', {
      equipment: this.getEquipment(),
      model,
      config,
    });
  };
  renderHandpieceDeviceStatusAlerts = () => {
    const { sState } = this.props;
    const e = this.getEquipment();
    const ispb = isPB(sState);
    const isHpDebugModeOn = getDebugStatusHandpiece(sState, e.deviceId);

    const display = [
      {
        state: userHasRole([0], sState) && isHpDebugModeOn,
        title: 'Debug Mode On',
        color: colors.error,
      },
    ].filter(i => i.state);

    return display.length === 0 ? null : (
      <div
        css={css(
          ispb
            ? 'width: 100%; margin-bottom: 10px;'
            : styles.deviceStatusAlertContainer
        )}
      >
        {display.map(d => (
          <div
            key={d.title}
            css={css(styles.deviceStatusAlert, `background-color: ${d.color}`)}
          >
            {d.title}
          </div>
        ))}
      </div>
    );
  };
  renderDeviceStatusAlerts = () => {
    const { sState } = this.props;
    const deviceStatus = this.getDeviceStatus();
    const e = this.getEquipment();
    const dState = getDeviceStatusFromEquip(e);
    const ispb = isPB(sState);

    const display = [
      {
        state: dState.isAlwaysOn
          ? false
          : deviceStatus === 'off' || !deviceStatus,
        title: 'OFFLINE',
        color: colors.statusOff,
      },
      {
        state: dState.highVoltage,
        title: 'Power Up Override',
        color: colors.statusStandby,
      },
      {
        state: !dState.scheduleEnabled,
        title: 'Equipment Schedule Disabled',
        color: colors.statusOff,
      },
      {
        state: userHasRole([0], sState) && dState.debug,
        title: 'Debug Mode On',
        color: colors.error,
      },
      {
        state: userHasRole([0], sState) && !get(e, 'showOnDash'),
        title: 'ShowOnDash Set to FALSE',
        color: colors.error,
      },
      {
        state: dState.highPressure,
        title: 'High Pressure Mode On',
        color: colors.statusStandby,
      },
      {
        state: dState.maint,
        title: 'Maintenance Mode On',
        color: colors.error,
      },
    ].filter(f => f.state);

    return display.length === 0 ? null : (
      <div
        css={css(
          ispb
            ? 'width: 100%; margin-bottom: 10px;'
            : styles.deviceStatusAlertContainer
        )}
      >
        {display.map(d => (
          <div
            key={d.title}
            css={css(styles.deviceStatusAlert, `background-color: ${d.color}`)}
          >
            {d.title}
          </div>
        ))}
      </div>
    );
  };
  renderBadAccessAlert = () => {
    Modal.warn({
      title: 'Functionality Not Available',
      content:
        //tslint:disable-next-line: max-line-length
        'Your user account does not have access to complete this functionality.\
        If you feel that this is incorrect, please contact your company Admin.',
      onOk: () => {
        return 0;
      },
    });
  };
  goToViewTGroup = () => {
    const { tandemGroup, model } = this.state;
    tandemGroup &&
      this.props.history.push('/dashboard/viewTandemGroup', {
        equipment: this.state.equipment,
        model,
        tandemGroup,
        showSideBar: true,
        back: `< Back to Equipment Details`,
      });
  };

  goToAddTGroup = () => {
    if (userHasRole(6, this.props.sState)) {
      this.renderBadAccessAlert();
      return;
    }
    const equipment = this.getEquipment();
    const config = getEquipConfig(equipment, this.props.sState);
    const fwVersion: number = get(config, 'fw_version');
    if (fwVersion < 34) {
      return renderFWErrorModal(
        equipment.equipmentSN as string,
        fwVersion.toString()
      );
    }
    const { model } = this.state;
    this.props.history.push('/dashboard/addTandemGroup', {
      equipment: this.state.equipment,
      locationId: this.state.equipment.locationId,
      orgId: this.state.equipment.dentalOrgId,
      model,
      tandemGroup: undefined,
      editMode: false,
      isAdd: true,
      showSideBar: true,
      back: `< Back to Equipment Details`,
    });
  };

  onAddEquipToTG = async (selectedTG: SelectValue) => {
    const { availableTGroups } = this.state;
    const equipment = this.getEquipment();
    const _availableTGroups = (availableTGroups as unknown) as TandemGroup[];
    const tandemGroup = _availableTGroups.find(
      (tg: TandemGroup) => tg.id === selectedTG
    ) as TandemGroup;

    try {
      const arg = generateTGDeviceCMD(tandemGroup.groupNumber, TG_ROLES.PEER);
      await sendDeviceCommand(
        get(equipment, 'deviceId', ''),
        { arg },
        {
          tandem: {
            tandemGroupNumber: get(tandemGroup, 'groupNumber'),
            tandemGroupRole: TG_ROLES.PEER,
          },
        }
      );
      await this.retrieveEquipment();
      this.setState({
        success: `${equipment.equipmentSN
          } successfully added to tandem group${get(tandemGroup, 'name')}`,
      });
    } catch (error) {
      this.setState({
        error: 'There was a problem adding the Equipment to the Tandem Group',
      });
      console.warn(error);
    }
    return true;
  };
  toggleModal = () => {
    const equipment = this.getEquipment();
    const config = getEquipConfig(equipment, this.props.sState);
    const fwVersion: number = get(config, 'fw_version');
    if (fwVersion < 34) {
      return renderFWErrorModal(
        equipment.equipmentSN as string,
        fwVersion.toString()
      );
    }
    this.setState({
      addEquipToTGModalVisible: !this.state.addEquipToTGModalVisible,
    });
  };

  render() {
    const { sState } = this.props;
    const {
      loading,
      loadingSensor,
      error,
      success,
      serviceOrg,
      dentalOrg,
      model,
      modalVisible,
      selectedPublish,
      sendingCommand,
      showAllMessages,
      modalChart,
      showAllHistory,
      tandemGroup,
      addEquipToTGModalVisible,
      availableTGroups,
      equipment,
      latestEquipmentPublishData,
    } = this.state;
    const e = equipment || this.getEquipment();
    //tslint:disable-next-line:no-any
    const chosenSensor = get(modalChart, 'chosenSensor') as any;
    const eMap = get(sState, `dash.equipMap.${e.deviceId}`);
    const config = getEquipConfig(e, sState);
    const alertMap = getRelevantAlertMap(keyBy(this.activeAlerts, 'data.source'));
    const selPubSourceInMap = get(
      alertMap,
      get(selectedPublish, 'data.source')
    );
    const alerts = get(eMap, 'alerts', []);
    const selPubIsActive = get(selPubSourceInMap, 'id') === get(selectedPublish, 'id');

    const ispb = isPB(sState);

    const eqType = getEqType(equipment);

    const sensorInfo = this.getSensorInfo();
    const infoRows = generateInfoRows(e, {
      model,
      sState,
      loading: loadingSensor,
      click: this.clickCol,
      pubData: latestEquipmentPublishData,
      sensorInfo,
      alertMap,
      config,
    });

    const infoRest = infoRows ? (infoRows || []).slice(1) : [];

    const freshConfig = getEquipConfig(equipment, sState);

    const hideStatus =
      [ETYPES.chair, ETYPES.handpiece, ETYPES.sterilizer, ETYPES.aerasone_compressor, ETYPES.aerasone_vacuum].indexOf(eqType) > -1;

    return (
      <div css={css(styles.container, ispb && 'margin-bottom: 20px;')}>
        <Global styles={global} />
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: undefined })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: undefined })}
          />
        )}

        {ispb && this.renderDeviceStatusAlerts()}
        {/* {ispb && isHp && this.renderHandpieceDeviceStatusAlerts()} */}

        <div css={css(SharedStyles.row, `width: 100%;`)}>
          <Link onClick={this.goBack}>{'< Back'}</Link>
        </div>

        <div
          css={css(
            styles.container,
            !ispb && `flex-direction: row;`,
            ispb && 'margin-bottom: 0px;'
          )}
        >
          {!ispb && (
            <Sidebar
              equipment={e}
              showBadAccess={this.renderBadAccessAlert}
              serviceOrg={serviceOrg}
              dentalOrg={dentalOrg}
              model={model}
              onEditClick={this.onEditClick}
              config={freshConfig}
              showReplace={!userHasRole(7, sState)}
              waitForNewConfig={this.props.waitForNewConfig}
              updateEquipment={() => this.retrieveEquipment()}
              tandemGroup={tandemGroup}
              tandemGroups={availableTGroups}
              showTandemGroup
              goToViewTGroup={this.goToViewTGroup}
              goToAddTGroup={this.goToAddTGroup}
              toggleModal={this.toggleModal}
              goToAddUser={this.goToAddUser}
              goToBillingHistory={this.goToBillingHistory}
            />
          )}

          {ispb && (
            <ModelImage
              css={css('width: 100%; height: 142px; margin-bottom: 20px;')}
              model={model}
            />
          )}

          <div css={css(ispb ? styles.mainContainerPB : styles.mainContainer)}>
            {!ispb && this.renderDeviceStatusAlerts()}
            {/* {!ispb && isHp && this.renderHandpieceDeviceStatusAlerts()} */}

            <div css={css(SharedStyles.row)}>
              <FormTitle size={'16px'}>
                <div css={css(SharedStyles.row)}>
                  {e.name || get(model, 'name', '')}
                  {loading && (
                    <Icon
                      css={css`
                        margin-left: 10px;
                      `}
                      type={'loading'}
                    />
                  )}
                </div>
              </FormTitle>
              {!hideStatus && (
                <div
                  css={css(
                    SharedStyles.row,
                    `margin-left: auto;`
                  )}
                >
                  <span
                    css={css`
                      font-size: 16px;
                      margin-right: 5px;
                    `}
                  >
                    {this.getDeviceStatusText()}
                    {sendingCommand && (
                      <Icon css={css('margin-left: 4px;')} type="loading" />
                    )}
                  </span>

                  <Switch
                    onChange={this.toggleChange}
                    checked={this.getDeviceStatus() === 'on'}
                  />
                </div>
              )}
            </div>

            {this.renderSubTitle()}

            {!showAllMessages && !showAllHistory && infoRows && (
              <div>
                {this.renderInfoRow1(get(infoRows, 0))}

                {infoRest.map((ir, i) =>
                  this.renderInfoRowEx(ir, {
                    equipment,
                    sensorInfo,
                    includeLink: i === 0,
                  })
                )}

                {<div css={css(styles.hrMain)} />}
              </div>
            )}

            {!showAllHistory &&
              !showAllMessages &&
              isHandpiece(e) &&
              this.renderPerProfileUsage()}

            {!showAllHistory && this.renderMessages()}

            {!showAllMessages &&
              !showAllHistory &&
              this.renderServiceReminders()}

            {!showAllMessages && this.renderServiceRecords()}

            {ispb && !showAllHistory && !showAllMessages && (
              <Sidebar
                equipment={e}
                serviceOrg={serviceOrg}
                dentalOrg={dentalOrg}
                model={model}
                onEditClick={this.onEditClick}
                config={config}
                waitForNewConfig={this.props.waitForNewConfig}
                updateEquipment={() => this.retrieveEquipment()}
                isPhoneBreak={ispb}
                goToBillingHistory={this.goToBillingHistory}
              />
            )}
          </div>
        </div>

        <ModalEvent
          visible={modalVisible}
          allowClear={userHasRole([6, 7], sState) === false}
          equipment={e}
          publish={selectedPublish}
          isActive={selPubIsActive}
          onClear={() => {
            this.setState({ modalVisible: false });
            this.retrieveEquipment();
          }}
          onClose={() => this.setState({ modalVisible: false })}
        />

        <ModalChart
          loading={loading}
          latestSensorInfo={
            chosenSensor ? get(sensorInfo, `${chosenSensor}`) : null
          }
          deviceId={get(e, 'deviceId', '')}
          equipment={e}
          onClose={() => this.setState({ modalChart: undefined })}
          alerts={alerts}
          {...modalChart}
        />
        <AddEquipToTGModal
          visible={addEquipToTGModalVisible}
          onAddEquipToTG={this.onAddEquipToTG}
          availableTGroups={(availableTGroups as unknown) as TandemGroup[]}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}

export const EquipmentDetailsComponent = withRouter(_EquipmentDetailsComponent);
