/** @jsx jsx **/
import { jsx } from '@emotion/core';
import { Button } from "../../_shared/button";
import React from 'react';
import './a1-registration.css'

export interface A1InstallationConfirmationProps {
  confirm: () => void;
  error: string;
};

export const A1InstallationConfirmation = (props: A1InstallationConfirmationProps) => {
  if (props.error) {
    return <div>{props.error}</div>
  }
  return (<div>
    <Button onClick={props.confirm} title="Click here to confirm installation." />
  </div>);
};