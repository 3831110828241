import React from "react";
import { Modal } from "antd";
import { cleanPhoneNumber, validPhoneNumber } from "../utils";
import { FormComponentProps } from "antd/lib/form";
import { Row } from "../_shared/row";
import { Form } from "../_shared/form";
import { Button } from "../_shared/button";
import { StatesSelect } from "../_shared/lib/states";

export const AddLocationModal = (props: any) => {
    const formProps = {
        ...(props.formProps || {}),
        defaults: {},
        toDisplay: [
            {
                title: 'Name',
                var: 'name',
                options: {
                    rules: [
                        {
                            required: true,
                            message: 'Name is required. ',
                        },
                        {
                            max: 50,
                            message: 'Name is limited to 50 characters. ',
                        },
                    ],
                },
            },
            {
                title: 'Phone',
                var: 'phone',
                transform: (value: string) => cleanPhoneNumber(value),
                options: {
                    preserve: true,
                    validateTrigger: 'onBlur',
                    rules: [
                        {
                            // tslint:disable-next-line:no-any
                            validator: (rule: any, value: string, cb: any) => {
                                const passes = !value || validPhoneNumber(value);
                                cb(passes ? undefined : rule.message);
                            },
                            message: 'Phone needs to be a valid phone number. ',
                        },
                    ],
                },
            },
            {
                title: 'Address',
                var: 'address.address',
                options: {
                    validateTrigger: 'onBlur',
                    rules: [
                        {
                            required: true,
                            message: 'Address is required',
                        },
                    ],
                },
            },
            {
                title: 'Address 2',
                var: 'address.address2'
            },
            {
                title: 'City',
                var: 'address.city',
                options: {
                    validateTrigger: 'onBlur',
                    rules: [
                        {
                            required: true,
                            message: 'City is required ',
                        },
                    ],
                },
            },
            {
                title: 'State',
                var: 'address.state',
                type: 'dropdown',
                opts: StatesSelect,
                style: `
          float: left;
          width: 50%;
          padding-right: 5px;
        `,
                options: {
                    validateTrigger: 'onBlur',
                    rules: [
                        {
                            required: true,
                            message: 'State is required ',
                        },
                    ],
                },
            },
            {
                title: 'ZIP Code',
                var: 'address.zip',
                style: `
          float: left;
          width: 50%;
          padding-left: 5px;
        `,
                options: {
                    validateTrigger: 'onBlur',
                    rules: [
                        {
                            required: true,
                            message: 'Zip Code is required ',
                        },
                    ],
                },
            },
        ]
    }

    return !props.visible ? null : (
        <Modal {...props}>
            <Form {...formProps as unknown as FormComponentProps} />
            <Row center style={{ float: 'none', width: '100%' }}>
                <Button title="Submit" onClick={props.onSubmit} />
            </Row>
        </Modal>
    )
}