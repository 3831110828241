import { set, get } from 'lodash';
import { Publish } from './publishData';

export interface EquipmentStatus {
  coreid: string;
  dz_config?: Publish;
  dz_schedule?: Publish;
  dz_alert?: Publish;
  alertMap: {};
  dz_thresholds?: Publish;
  dz_maint?: Publish;
  dz_sensor_statistics?: Publish;
  dz_sensor_instant?: Publish;
  dz_profile_runtimes?: Publish;
}

export class _EquipmentStatus implements EquipmentStatus {
  constructor(props = {}) {
    for (let key in props) {
      const val = get(props, key);
      set(this, key, val);
    }
  }
  [key: string]: string | {} | Publish | undefined;

  coreid = '';
  dz_config?: Publish;
  dz_schedule?: Publish;
  dz_alert?: Publish;
  alertMap = {};
  dz_thresholds?: Publish;
  dz_maint?: Publish;
  dz_sensor_statistics?: Publish;
  dz_sensor_instant?: Publish;
  dz_profile_runtimes?: Publish;
}

export interface AlertStatus {
  error: number;
  source: string;
  time: number;
}

export interface MaintStatus {
  timeDiff: number;
  key: string;
  isVac: Boolean;
}

export interface TestStatus {
  overall: number;
  date: string;
}

export interface EQStatus {
  alerts: AlertStatus[];
  maint: MaintStatus[];
  test?: TestStatus;
}

export class _EQStatus {
  [key: string]: AlertStatus[] | MaintStatus[] | TestStatus | undefined;

  alerts = []; //list of alerts that aren't zero
  maint = []; //list of maint triggers
  test = undefined; //list of maint triggers
}
